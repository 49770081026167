import {
  Lightning,
  Thermometer,
  SquaresFour,
  ChartLineUp,
} from "phosphor-react"
import { Container } from "./styles"

const ICON_SIZE = 14
const ICON_WEIGHT = "bold"
const icons = {
  temperature: {
    name: "temperature",
    icon: <Thermometer size={ICON_SIZE} weight={ICON_WEIGHT} />,
  },
  rms2: {
    name: "rms2",
    icon: <Lightning size={ICON_SIZE} weight={ICON_WEIGHT} />,
  },
  rmms: {
    name: "rmms",
    icon: <Lightning size={ICON_SIZE} weight={ICON_WEIGHT} />,
  },
  tilt: {
    name: "tilt",
    icon: <Lightning size={ICON_SIZE} weight={ICON_WEIGHT} />,
  },
  fft: {
    name: "fft",
    icon: <ChartLineUp size={ICON_SIZE} weight={ICON_WEIGHT} />,
  },
  accRaw: {
    name: "accRaw",
    icon: <Lightning size={ICON_SIZE} weight={ICON_WEIGHT} />,
  },
  _4t20: {
    name: "_4t20",
    icon: <SquaresFour size={ICON_SIZE} weight={ICON_WEIGHT} />,
  },
  ntc: {
    name: "ntc",
    icon: <Lightning size={ICON_SIZE} weight={ICON_WEIGHT} />,
  },
  pot: {
    name: "pot",
    icon: <SquaresFour size={ICON_SIZE} weight={ICON_WEIGHT} />,
  },
}

interface IconServiceProps {
  serviceType:
    | "temperature"
    | "rms2"
    | "rmms"
    | "tilt"
    | "fft"
    | "accRaw"
    | "_4t20"
    | "ntc"
    | "pot"
  isConfigured?: boolean
}

export const IconService = ({
  serviceType,
  isConfigured = false,
}: IconServiceProps) => {
  const { name, icon } = icons[serviceType]

  return (
    <Container title={name} $isConfigured={isConfigured}>
      {icon}
    </Container>
  )
}
