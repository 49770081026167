import * as S from "./styles"

type CardDateProps = {
  date: Date | undefined
}

export const CardDate = ({ date }: CardDateProps) => {
  const DateToString = (date: Date | undefined) => {
    if (!date) return ""
    return date.toLocaleString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    })
  }

  const dateInString = DateToString(date)

  return <S.CardDate>{dateInString}</S.CardDate>
}
