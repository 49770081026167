import { FC } from "react"
import * as S from "./styles"
import { PiCaretDownBold, PiCaretUpBold } from "react-icons/pi"
import theme from "../../../../styles/theme"
import { useDashDropdownController } from "./controller/useDashDropdownController"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { ValidatePermission } from "../../../../utils/validatePermission"
import { PermissionsProteu } from "../../../../store/features/user/user.interfaces"
import { Plus } from "phosphor-react"

export const DashDropdown: FC = () => {
  const { buttons } = useAppTranslate()

  const {
    toggleDropdown,
    selectedOption,
    isOpen,
    dashboards,
    handleOptionClick,
    handleAddDash,
  } = useDashDropdownController()

  return (
    <S.CustomSelectContainer>
      <S.DropdownButton
        data-testid='drop-down__isOpen-btn'
        onClick={toggleDropdown}
      >
        <S.TitleDashboard>{selectedOption}</S.TitleDashboard>
        <S.IconDropDown>
          {isOpen ? (
            <PiCaretUpBold color={theme.palette.gray[800]} size={18} />
          ) : (
            <PiCaretDownBold color={theme.palette.gray[800]} size={18} />
          )}
        </S.IconDropDown>
      </S.DropdownButton>
      <S.DropdownContainer>
        {isOpen && (
          <>
            <S.DropdownList data-testid='drop-down__list' $isopen={isOpen}>
              {dashboards.data.map((dashboard, index) => {
                return (
                  <S.DropdownItem
                    key={dashboard.id}
                    onClick={() => handleOptionClick(dashboard.name, index)}
                  >
                    {dashboard.name}
                  </S.DropdownItem>
                )
              })}
            </S.DropdownList>
            <S.DropdownItemBtn>
              <ValidatePermission permission={PermissionsProteu.DashboardWrite}>
                <S.AddButton
                  data-testid='drop-down__add-btn'
                  onClick={handleAddDash}
                >
                  {buttons.new_dashboard}
                  <Plus
                    size={16}
                    color={theme.palette.success.main}
                    weight={"bold"}
                  />
                </S.AddButton>
              </ValidatePermission>
            </S.DropdownItemBtn>
          </>
        )}
      </S.DropdownContainer>
    </S.CustomSelectContainer>
  )
}
