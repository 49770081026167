import { FC } from "react"
import * as S from "./styles"
import { Title } from "../Title"
import { useAppTranslate } from "../../translate/useAppTranslate"
import { AnalyticPeriod } from "../../pages/Dashboard/entities/DashboardContextDTO"

interface SelectPeriodProps {
  disabled?: boolean
  handleOnChange: (value: number) => void
}

export const SelectPeriod: FC<SelectPeriodProps> = ({
  disabled = false,
  handleOnChange,
}) => {
  const { modalAnalyticConfig } = useAppTranslate()

  const period = [
    {
      id: 1,
      value: AnalyticPeriod.LAST_MINUTE,
      label: modalAnalyticConfig.last_minute,
    },
    {
      id: 2,
      value: AnalyticPeriod.LAST_10_MINUTES,
      label: modalAnalyticConfig.last_10_minutes,
    },
    {
      id: 3,
      value: AnalyticPeriod.LAST_30_MINUTES,
      label: modalAnalyticConfig.last_30_minutes,
    },
    {
      id: 4,
      value: AnalyticPeriod.LAST_HOUR,
      label: modalAnalyticConfig.last_hour,
    },
    {
      id: 5,
      value: AnalyticPeriod.LAST_5_HOURS,
      label: modalAnalyticConfig.last_5_hours,
    },
    {
      id: 6,
      value: AnalyticPeriod.LAST_12_HOURS,
      label: modalAnalyticConfig.last_12_hours,
    },
    {
      id: 7,
      value: AnalyticPeriod.LAST_DAY,
      label: modalAnalyticConfig.last_day,
    },
    {
      id: 8,
      value: AnalyticPeriod.LAST_WEEK,
      label: modalAnalyticConfig.last_week,
    },
  ]

  return (
    <S.Period data-testid='select-period'>
      <S.LabelTitleAnalytic>
        {" "}
        <Title label={modalAnalyticConfig.period + " :"} size='sm' />
      </S.LabelTitleAnalytic>
      <S.SelectPeriod
        defaultValue={"DEFAULT"}
        disabled={disabled}
        onChange={(e) => handleOnChange(Number(e.target.value))}
      >
        <option data-testid='select-period__option' value={"DEFAULT"} disabled>
          {modalAnalyticConfig.period_text}
        </option>
        {period.map((element) => {
          return (
            <option value={element.value} key={element.value}>
              {element.label}
            </option>
          )
        })}
      </S.SelectPeriod>
    </S.Period>
  )
}
