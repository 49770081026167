import { FloatingButton } from "../../../components/Buttons/FloatingButton"
import { ChartContainer } from "../components/ChartContainer"
import { Button } from "../../../components/Buttons"
import * as Modal from "../../../components/Modal"
import * as S from "./styles"
import { useAppTranslate } from "../../../translate/useAppTranslate"
import { ValidatePermission } from "../../../utils/validatePermission"
import { LoadingSpinner } from "../../../components/LoadingSpinner"
import useDashboardController from "../controller/useDashboardController"
import { PermissionsProteu } from "../../../store/features/user/user.interfaces"
import { ModalWrapper } from "../components/ModalWrapper"
import { useContext } from "react"
import {
  CreateChartContext,
  CreateChartContextDTO,
} from "../context/CreateChartContext"

export const DashboardWrapper = (): JSX.Element => {
  const { dashboard, buttons } = useAppTranslate()

  const { handleOpenConfigureChart } = useContext(
    CreateChartContext
  ) as CreateChartContextDTO

  const {
    charts,
    handleDeleteChart,
    handleEditChart,
    handleOpenModalConfigDash,
    handleSaveReportADL,
    graphicsPerLine,
    isLoading,
    firstAccess,
    hasGraphics,
  } = useDashboardController()

  if (isLoading && !hasGraphics) {
    return (
      <S.Wrapper>
        <S.CenterLoading>
          <LoadingSpinner />
        </S.CenterLoading>
      </S.Wrapper>
    )
  }

  return (
    <S.Wrapper>
      {firstAccess && (
        <S.InformationNoChartContainer>
          <S.InformationNoChartText>
            {dashboard.configure_your_first_dashboard}
          </S.InformationNoChartText>
          <S.InformationNoChartBtn>
            <Button
              label={buttons.new_dashboard}
              onClick={handleOpenModalConfigDash}
            />
          </S.InformationNoChartBtn>
        </S.InformationNoChartContainer>
      )}

      {!firstAccess && hasGraphics && (
        <S.ContainerCharts>
          {charts.map((chart) => {
            return (
              <ChartContainer
                graphics_line={graphicsPerLine}
                key={chart.id}
                onDelete={() => handleDeleteChart(chart.id, chart.dashboardId)}
                onEdit={handleEditChart}
                onSaveADL={handleSaveReportADL}
                chart={chart}
              />
            )
          })}
        </S.ContainerCharts>
      )}

      {!firstAccess && !hasGraphics && (
        <S.InformationNoChartContainer>
          <S.InformationNoChartText>
            {dashboard.no_graphics_configured}
          </S.InformationNoChartText>
          {dashboard.information_to_add_graph}
        </S.InformationNoChartContainer>
      )}

      {!firstAccess && (
        <ValidatePermission permission={PermissionsProteu.DashboardWrite}>
          <FloatingButton onClick={handleOpenConfigureChart} />
        </ValidatePermission>
      )}

      <Modal.Root />

      <ModalWrapper />
    </S.Wrapper>
  )
}
