import { Skeleton } from "@mui/material"
import { TitleAndTag } from "../../../ui/TitleAndTag"
import { CardDate } from "../CardDate"
import * as S from "./styles"

type CardHeaderProps = {
  title: string
  subTitle?: string
  date?: Date
  icon: JSX.Element | undefined
  status?: "read" | "unread"
  type: "notification" | "message"
  skeleton?: boolean
}

export const CardHeader = ({
  title,
  subTitle = "",
  date,
  icon,
  status = "read",
  type,
  skeleton = false,
}: CardHeaderProps) => {
  return (
    <S.CardHeader>
      {skeleton ? (
        <>
          <Skeleton variant='text' width={"80%"} />
        </>
      ) : (
        <>
          <TitleAndTag
            title={title}
            tag={subTitle}
            icon={icon}
            status={status}
            type={type}
          />
          <CardDate date={date} />
        </>
      )}
    </S.CardHeader>
  )
}
