import { ReactNode } from "react"
import { ColorType, Size, Weight, Content } from "./styles"

interface TextProps {
  family?: string
  fontSize?: Size
  fontWeight?: Weight
  color?: ColorType
  children: ReactNode
}

export const Text = ({
  color = "gray.600",
  family,
  fontSize = "sm",
  fontWeight = "regular",
  children,
}: TextProps) => {
  return (
    <Content
      $color={color}
      $family={family}
      $fontSize={fontSize}
      $fontWeight={fontWeight}
    >
      {children}
    </Content>
  )
}
