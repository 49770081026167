import { SensorAndServices } from "../../../../components/Modal/components/SensorAndServices"
import { ModalHedro } from "../../../../components/ModalHedro"
import { useAppTranslate } from "../../../../translate/useAppTranslate"

interface ModalConfigureSensorsProps {
  onClose: () => void
  onBack: () => void
  onAction: () => void
  isDisabled: boolean
}

export const ModalConfigureSensors = ({
  onClose,
  onBack,
  onAction,
  isDisabled,
}: ModalConfigureSensorsProps) => {
  const {
    dashboard: { configureSensors },
  } = useAppTranslate()

  return (
    <ModalHedro
      onClose={onClose}
      title={configureSensors.title}
      subtitle={configureSensors.subtitle}
      onBack={onBack}
      onAction={onAction}
      actionBar={configureSensors.submit}
      size='lg'
      disableAction={isDisabled}
    >
      <SensorAndServices />
    </ModalHedro>
  )
}
