import styled from "styled-components"

export const Asset = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`
