import { AssetDescriptionSkeleton } from "../../../components/Description/Skeleton"
import { AssetPropertiesSkeleton } from "../../../components/Properties/Skeleton"
import { AssetTypeSkeleton } from "../../../components/Type/Skeleton"
import { Divider } from "../../../../../../components/ui/Divider"
import { Container } from "./styles"
import { AssetWithSensorSkeleton } from "../../../components/AssetWithSensor/AssetWithSensor.skeleton"

export const AssetDetailsSkeleton = () => (
  <Container>
    <AssetDescriptionSkeleton />
    <Divider />
    <AssetTypeSkeleton />
    <Divider />
    <AssetPropertiesSkeleton />
    <Divider />
    <AssetWithSensorSkeleton />
  </Container>
)
