import styled from "styled-components"
import { typography } from "../../../styles/theme/proteu/typography"
import { palette } from "../../../styles/theme/proteu/palette"
import {
  BasicColors,
  ColorShade,
  GrayColorShade,
  TextColorType,
} from "../../../styles/default/palette.interface"

export type ColorType =
  | `${BasicColors}.${ColorShade}`
  | "secondary.main"
  | `gray.${GrayColorShade}`

export type Size = "xs" | "sm" | "md" | "lg"
export type Weight = "regular" | "medium" | "semibold" | "bold"

interface TextProps {
  $family?: string
  $fontSize: Size
  $fontWeight: Weight
  $color: ColorType
}

const setFontSize = (type: Size) => {
  switch (type) {
    case "lg":
      return typography.text.lg.fontSize
    case "md":
      return typography.text.md.fontSize
    case "sm":
      return typography.text.sm.fontSize
    case "xs":
      return typography.text.xs.fontSize
  }
}

const setLineHeight = (type: Size) => {
  switch (type) {
    case "lg":
      return typography.text.lg.lineHeight
    case "md":
      return typography.text.md.lineHeight
    case "sm":
      return typography.text.sm.lineHeight
    case "xs":
      return typography.text.xs.lineHeight
  }
}

const setFontWeight = (type: Weight) => {
  switch (type) {
    case "regular":
      return typography.fontWeightRegular
    case "medium":
      return typography.fontWeightMedium
    case "semibold":
      return typography.fontWeightSemibold
    case "bold":
      return typography.fontWeightBold
  }
}

const setColor = (color: ColorType): string => {
  const [_type, _shade] = color.split(".")

  const type = _type as TextColorType
  const shade = _shade as ColorShade | GrayColorShade

  return palette[type][shade]!
}

export const Content = styled.p<TextProps>`
  font-family: ${({ $family, theme }) =>
    $family || theme.typography.fontFamily};
  font-size: ${({ $fontSize }) => setFontSize($fontSize)};
  line-height: ${({ $fontSize }) => setLineHeight($fontSize)};
  font-weight: ${({ $fontWeight }) => setFontWeight($fontWeight)};
  color: ${({ $color }) => setColor($color)};
`
