import styled from "styled-components"
import { media } from "../../../../../utils/media-queries"

export const MobileContent = styled.div<{ $isOpen: boolean }>`
  display: ${({ $isOpen }) => ($isOpen ? "flex" : "none")};

  align-items: center;
  justify-content: center;

  @media ${media.small} {
    display: none;
  }

  padding: 12px 0;

  border-top: 1px solid ${({ theme }) => theme.palette.gray[400]};
  background-color: ${({ theme }) => theme.palette.gray[200]} !important;
`

export const MobileDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
`

export const DetailTitle = styled.div`
  display: flex;
  align-items: center;

  padding-left: 16px;

  background-color: ${({ theme }) => theme.palette.gray[200]};
`

export const DetailText = styled.div`
  display: flex;
  align-items: center;

  padding-left: 16px;
`
