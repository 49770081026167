import { ModalHedro } from "../../../../../components/ModalHedro"
import { Input } from "../../../../../components/ui/Input"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"

interface ModalRenameProps {
  handleCloseModal: () => void
  renameGateway: () => void
  handleEditNameGateway: (e: React.ChangeEvent<HTMLInputElement>) => void
  isLoading: boolean
}

export const ModalRename = ({
  handleCloseModal,
  renameGateway,
  handleEditNameGateway,
  isLoading,
}: ModalRenameProps) => {
  const { gateways, buttons } = useAppTranslate()

  return (
    <ModalHedro
      size='md'
      title={gateways.renameGateway}
      actionBar={buttons.save}
      onClose={handleCloseModal}
      onAction={renameGateway}
      disableAction={isLoading}
    >
      <Input
        onChange={(e) => handleEditNameGateway(e)}
        label={gateways.gatewayName}
        placeholder={gateways.enterName}
      />
    </ModalHedro>
  )
}
