import { UseFormReturn, useFieldArray, useWatch } from "react-hook-form"
import { Input } from "../../../../../components/ui/Input"
import { AssetSchema, SCHEMA_ERRORS } from "../../utils/schema"
import {
  Wrapper,
  BasicInfo,
  AssetInfo,
  AssetDescription,
  Content,
  PropertiesMessage,
  PropertiesFields,
} from "./styles"
import { useEffect, useRef } from "react"
import { AssetType, getProperties } from "../../../../../utils/entities/assets"
import { Divider } from "../../../../../components/ui/Divider"
import { TextArea } from "../../../../../components/ui/TextArea"
import { AssetFormSkeleton } from "./AssetForm.skeleton"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"
import { InputSelect } from "../Select"
import { Text } from "../../../../../components/ui/Text"

const getTypeLabel = (type: number) => {
  switch (type) {
    case AssetType.LOCAL:
      return "Local"
    case AssetType.MOTOR:
      return "Motor"
    case AssetType.ADL:
      return "ADL"
    default:
      return ""
  }
}

interface AssetFormProps {
  form: UseFormReturn<AssetSchema>
  isConfigure?: boolean
  defaultValue?: string
  isRoot?: boolean
}

const AssetForm = ({
  form,
  isConfigure = false,
  defaultValue,
  isRoot = false,
}: AssetFormProps) => {
  const { assets } = useAppTranslate()

  const getErrorMessage = (message: string | undefined): string | undefined => {
    if (!message) return undefined

    switch (message) {
      case SCHEMA_ERRORS.INVALID_FILE_SIZE:
        return assets.form.errors.invalidFileSize
      case SCHEMA_ERRORS.INVALID_IMAGE_TYPE:
        return assets.form.errors.invalidFileType
      case SCHEMA_ERRORS.REQUIRED:
        return assets.form.errors.required
      default:
        return assets.form.errors.default
    }
  }

  const {
    control,
    setFocus,
    register,
    // clearErrors,
    formState: { errors },
  } = form

  const { fields, append, remove } = useFieldArray({
    control,
    name: "properties",
  })

  const type = useWatch({
    control,
    name: "type",
  })

  // const date = useWatch({
  //   control,
  //   name: "associatedAt",
  // })

  const isFirstConfigureRender = useRef(isConfigure)

  useEffect(() => {
    if (!type) return

    if (isFirstConfigureRender.current) {
      isFirstConfigureRender.current = false
      return
    }

    const properties = getProperties(Number(type))

    remove()

    properties.forEach((property) => {
      append({ property, value: "" })
    })
  }, [remove, append, type, fields.length, setFocus])

  useEffect(() => {
    if (fields.length <= 0) return

    if (isConfigure) setFocus("name")
    if (!isConfigure) setFocus("type")
  }, [fields, isConfigure, setFocus])

  const options: number[] = Object.keys(AssetType)
    .filter((key) => !isNaN(Number(key)) && key !== "1")
    .map((key) => Number(key))

  // const handleSelectSensor = (id: string | number) => {
  //   form.setValue("sensorId", id.toString())
  // }

  return (
    <Wrapper>
      <AssetInfo>
        {/* <ImageContainer
          editable
          {...register("image")}
          error={getErrorMessage(errors.image?.message as string)}
          onReset={() => clearErrors("image")}
        /> */}

        <BasicInfo>
          <Text fontSize='md' fontWeight='bold'>
            {assets.form.titles.informations}
          </Text>
          <PropertiesFields>
            <Input
              label={assets.form.name}
              placeholder={assets.form.namePlaceholder}
              error={getErrorMessage(errors.name?.message)}
              {...register("name")}
            />

            {!isRoot && (
              <InputSelect
                label={assets.details.type}
                options={options}
                error={getErrorMessage(errors.type?.message)}
                renderLabel={(option) => getTypeLabel(Number(option))}
                {...register("type")}
              />
            )}
          </PropertiesFields>
        </BasicInfo>
      </AssetInfo>

      <AssetDescription>
        <TextArea
          label={assets.details.description}
          rows={10}
          placeholder={assets.form.descriptionPlaceholder}
          error={getErrorMessage(errors.description?.message)}
          {...register("description")}
        />
      </AssetDescription>

      <Divider />

      <Content>
        <Text fontSize='md' fontWeight='bold'>
          {assets.form.titles.properties}
        </Text>

        {fields.length === 0 && (
          <PropertiesMessage>
            <Text fontSize='sm' fontWeight='regular'>
              {assets.form.propertiesPlaceholder}
            </Text>
          </PropertiesMessage>
        )}

        {fields.length > 0 && (
          <PropertiesFields>
            {fields.map((field, index) => {
              return (
                <Input
                  key={field.id}
                  label={field.property}
                  placeholder={`${field.property}`}
                  {...register(`properties.${index}.value`)}
                />
              )
            })}
          </PropertiesFields>
        )}
      </Content>

      {/* <Divider /> */}

      {/*
      <Content>
        <Title label='Sensor' size='sm' weight='medium' />

        <DatetimeInput
          isEmpty={!date}
          error={errors.associatedAt && assets.form.errors.required}
          label={assets.form.sensors.associatedAt}
          type='datetime-local'
          {...register("associatedAt")}
        />

        <AssetSensorsTable
          onCheck={handleSelectSensor}
          defaultValue={defaultValue}
        />
        <Text color='error.main' fontSize='xs'>
          {errors.sensorId && assets.form.errors.required}
        </Text>
      </Content> */}
    </Wrapper>
  )
}

export { AssetForm, AssetFormSkeleton }
