import { QueryCacheLifecycleApi } from "@reduxjs/toolkit/dist/query/endpointDefinitions"
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/dist/query/react"
import SocketFactory, { ISocket } from "../../../services/SocketFactory"
import { toast } from "react-toastify"
import { formatedDate } from "../../../utils/date"
import { AdlAlarmData } from "./entities"

const getAlarmMessage = (data: AdlAlarmData) => {
  if (data.alarmType === "AdlThresholdAlarm")
    return `Vibração acima do limite operacional na ${data.sensorName} ultrapassado às ${formatedDate(data.time)}.`

  if (data.intensity === 1)
    return `Anomalia identificada no ${data.sensorName} às ${formatedDate(data.time)}, intensidade nível ${data.intensity}.`

  return `Aumento da intensidade do alerta às ${formatedDate(data.time)} pelo ${data.sensorName}, intensidade nível ${data.intensity}.`
}

export const onAlarmCacheEntryAdded = async (
  companyId: number,
  {
    updateCachedData,
    cacheDataLoaded,
    cacheEntryRemoved,
  }: QueryCacheLifecycleApi<
    number,
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    void,
    "adlApi"
  >
) => {
  const WS_ADL_ALARM_EVENT = `WS2CLIENT/ADL_ALARM/${companyId}`

  let socket!: ISocket

  try {
    socket = SocketFactory.create()

    socket.socket.on(WS_ADL_ALARM_EVENT, async (data: AdlAlarmData) => {
      if (!data) return

      toast.error(getAlarmMessage(data), {
        autoClose: false,
        theme: "colored",
      })

      const alarmSound = new Audio(require("../../../assets/warning.mp3"))

      alarmSound.play().catch((error) => console.log(error))

      setTimeout(() => {
        alarmSound.pause()
      }, 3000)
    })

    await cacheEntryRemoved
  } catch (error) {
    console.error(error)
  }

  await cacheEntryRemoved
  socket.socket.off(WS_ADL_ALARM_EVENT)
}
