import styled from "styled-components"

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

export const ContainerCharts = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const SingleChart = styled.div<{ $graphics_line: number }>`
  width: calc(100% / ${(props) => props.$graphics_line} - 2%);
  margin: 1%;
  box-sizing: border-box;
  @media (max-width: 1280px) {
    width: calc(
      100% /
        ${(props) => (props.$graphics_line === 3 ? 2 : props.$graphics_line)} -
        2%
    );
  }
  @media (max-width: 720px) {
    width: calc(100% / 1 - 2%);
  }
`

export const InformationNoChartContainer = styled.section`
  text-align: center;
  margin-top: 40vh;
  font-size: medium;
  flex-direction: column;
`

export const InformationNoChartText = styled.h2`
  margin-bottom: 10px;
`

export const InformationNoChartBtn = styled.div`
  display: flex;
  justify-content: center;
`
export const CenterLoading = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
`

export const DashMenu = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  width: 100%;
  @media only screen and (min-width: 600px) {
    justify-content: flex-end;
  }
`

export const DashboardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-right: 12px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`
