import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { Sensor } from "../../../utils/entities/assets"

interface FetchSensorsRequest {
  limit: number
  offset: number
}

interface FetchSensorsResponse {
  count: number
  data: Sensor[]
}

const pause = (duration: number | undefined) => {
  return new Promise((resolve) => {
    setTimeout(resolve, duration)
  })
}

export const assetSensorsApi = createApi({
  reducerPath: "assetSensors",
  baseQuery: fetchBaseQuery({
    baseUrl: "http://localhost:3005",
    fetchFn: async (...args) => {
      //"This section ('await pause') of code is for testing purposes only
      //and will be removed before production deployment."
      await pause(1000)
      return fetch(...args)
    },
  }),
  endpoints: (build) => ({
    fetchSensors: build.query<FetchSensorsResponse, FetchSensorsRequest>({
      query: ({ limit, offset }) => {
        return {
          url: `/sensors?_page=${offset}&_per_page=${limit}`,
          method: "GET",
        }
      },
    }),
  }),
})

export const { useFetchSensorsQuery } = assetSensorsApi
