import { Title } from "../Title"
import { Container, WrapperText } from "./styles"
import { TitleAndTextSkeleton } from "./TitleAndText.skeleton"

interface TitleAndTextProps {
  title?: string
  text?: string
}

const TitleAndText = ({ title = "", text = "" }: TitleAndTextProps) => {
  return (
    <Container>
      <Title label={title} size='xs' weight='semibold' />
      <WrapperText>
        <p>{text}</p>
      </WrapperText>
    </Container>
  )
}

export { TitleAndText, TitleAndTextSkeleton }
