import { useContext } from "react"
import { HDR_SERVICES_TYPE } from "hdr-process-data"
import { DashboardContext } from "../../../../../../../pages/Dashboard/context/DashboardContext"
import { DashboardContextDTO } from "../../../../../../../pages/Dashboard/entities/DashboardContextDTO"
import { SensorConfig } from "../../../../../../../store/features/sensors/sensors.interfaces"
import { useAppSelector } from "../../../../../../../store/hooks"
import { cleanAndPadSerialNumber } from "../../../../../../../utils/cleanAndPadSerialNumber"

const ServicesType = {
  1: [
    HDR_SERVICES_TYPE.temp,
    HDR_SERVICES_TYPE.rms2,
    HDR_SERVICES_TYPE.rmms,
    HDR_SERVICES_TYPE.tilt,
    HDR_SERVICES_TYPE._4t20,
    HDR_SERVICES_TYPE.ntc,
    HDR_SERVICES_TYPE.pot,
  ],
  2: [HDR_SERVICES_TYPE.fft],
  3: [HDR_SERVICES_TYPE.accRaw],
}

export const useTableSensorsController = () => {
  const { sensorAndServices, analyticToPlot, checkboxState, setCheckboxState } =
    useContext(DashboardContext) as DashboardContextDTO

  const sensors = useAppSelector(
    (state) => state.persistedReducer.sensors.config
  )

  const getCheckboxKey = (mac: string, service: string, axis?: string) => {
    if (!axis) return `${mac}/${service}`

    return `${mac}/${service}/${axis}`
  }

  const getCheckboxState = (mac: string, service: string, axis?: string) => {
    const key = getCheckboxKey(mac, service, axis)

    return !!checkboxState[key]
  }

  const handleChangeCheckbox = (mac: string, name: string, axis?: string) => {
    const key = getCheckboxKey(mac, name, axis)

    const aux = checkboxState
    aux[key] = !aux[key]

    const service = axis !== undefined ? name + axis : name

    if (aux[key]) {
      sensorAndServices.current.push({
        mac,
        name,
        service,
        axis,
      })
    } else {
      sensorAndServices.current = sensorAndServices.current.filter((value) => {
        return value.mac !== mac && value.name !== name
      })
    }

    setCheckboxState({ ...aux })
  }

  const filteredSensors = () => {
    if (!analyticToPlot.current) return []
    const type = analyticToPlot.current.type as 1 | 2 | 3

    if (!type) return []

    const filtered: SensorConfig[] = sensors.map((sensor) => {
      return {
        mac: sensor.mac,
        serialNumber: sensor.serialNumber,
        id: sensor.id,
        name: sensor.name,
        services: sensor.services.filter((value) =>
          ServicesType[type].includes(value.type)
        ),
      }
    })

    return filtered
  }

  const getNameSensor = (name: string, serialNumber: string) => {
    if (name && name.trim() !== "") return name.trim()
    if (serialNumber && serialNumber.trim() !== "")
      return cleanAndPadSerialNumber(serialNumber)
    return "Deleted"
  }

  return {
    checkboxState,
    getCheckboxState,
    handleChangeCheckbox,
    sensors: filteredSensors(),
    getNameSensor,
  }
}
