import { Title } from "../../Title"
import { CardIcon, CardIconAndTitle, CardTag } from "./styles"

type TitleAndTagProps = {
  title?: string
  tag?: string
  icon?: React.ReactNode
  status: "read" | "unread"
  type: "notification" | "message"
}

export const TitleAndTag = ({
  title = "",
  tag = "",
  icon,
  status = "read",
  type,
}: TitleAndTagProps) => {
  return (
    <div>
      <CardIconAndTitle $status={status}>
        <CardIcon type={type}>{icon}</CardIcon>
        <Title label={title} size='xs' weight='semibold' />
      </CardIconAndTitle>
      <CardTag>{tag}</CardTag>
    </div>
  )
}
