import { ModalHedro } from "../../../../../../components/ModalHedro"
import { useFetchAssetChildrenQuery } from "../../../../../../store/store"
import { AssetIcon } from "../../../List/components/TreeAssets/components/AssetIcon"
import { Text } from "../../../../../../components/ui/Text"
import { Asset, Body } from "./styles"
import { useAppTranslate } from "../../../../../../translate/useAppTranslate"
import { AssetData } from "../../../../../../utils/entities/assets"

export const ModalDeleteAsset = ({
  id,
  handleOnClose,
  handleDelete,
  isDisable,
}: {
  id: number
  handleOnClose: () => void
  handleDelete: () => void
  isDisable: boolean
}) => {
  const { assets } = useAppTranslate()

  const { data, isLoading, isError } = useFetchAssetChildrenQuery(id)

  return (
    <ModalHedro
      onClose={handleOnClose}
      onAction={handleDelete}
      actionBar={assets.form.deleteAsset}
      title={assets.form.deleteModalTitle}
      disableAction={isLoading || isError || isDisable}
    >
      <Content data={data} isLoading={isLoading} isError={isError} />
    </ModalHedro>
  )
}

const Content = ({
  isError,
  isLoading,
  data,
}: {
  isLoading: boolean
  isError: boolean
  data: AssetData[] | undefined
}) => {
  const { assets } = useAppTranslate()

  if (isLoading) return <Text>{assets.details.loading}</Text>

  if (isError || !data) return <Text>{assets.details.error}</Text>

  return (
    <Body>
      <Text color='gray.600'>
        {data.length === 0
          ? assets.form.deleteMessage
          : assets.form.deleteMessageWithChildren}
      </Text>

      {data.map((item) => (
        <Asset key={item.id}>
          <AssetIcon type={item.typeId} />
          <Text color='gray.800'>{item.name}</Text>
        </Asset>
      ))}
    </Body>
  )
}
