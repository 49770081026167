import styled from "styled-components"
import { media } from "../../../../utils/media-queries"
import { Font } from "../../../../styles/mixins/fonts.mixins"

export const ListDetailsGateways = styled.div<{ $isOpen: boolean }>`
  display: ${({ $isOpen }) => ($isOpen ? "flex" : "none")};
  ${Font({ size: "text.sm", lineHeight: "text.sm", fontWeight: "medium" })}

  .list-gateway-info {
    display: flex;
    padding: 6px 0px;
    span {
      text-transform: uppercase;
      padding-right: 5px;
    }
  }
  align-items: center;
  margin: auto;

  display: none;

  @media ${media.small} {
    display: ${({ $isOpen }) => ($isOpen ? "table-row" : "none")};
    position: relative;
    height: 54px;

    .table-row-expand-group {
      background-color: ${(props) => props.theme.palette.gray[100]};
      height: 100%;
      align-items: center;
      position: absolute;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;

      .list-items {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-evenly;
        flex-wrap: wrap;
      }
    }

    .list-gateway-info {
      display: flex;
      padding: 0 12px;
      ${Font({ size: "text.xs", lineHeight: "text.sm", fontWeight: "medium" })}

      p {
        font-weight: ${(props) => props.theme.typography.fontWeightRegular};
      }

      @media ${media.medium} {
        ${Font({
          size: "text.sm",
          lineHeight: "text.sm",
          fontWeight: "medium",
        })}
      }
    }
  }
`
