import { Children, cloneElement, isValidElement } from "react"
import { Container } from "./styles"

type ChildrenElement = {
  index: number
}

export const TabList = ({
  children,
  ...props
}: React.ComponentProps<"div">) => {
  return (
    <Container>
      {Children.map(children, (child, index) => {
        if (isValidElement<ChildrenElement>(child))
          return cloneElement(child, { index })
      })}
    </Container>
  )
}
