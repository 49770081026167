import { Skeleton } from "@mui/material"
import { Container } from "./styles"

export const InputSkeleton = () => {
  return (
    <Container>
      <Skeleton variant='text' width={64} height={18} />
      <Skeleton variant='rounded' width={300} height={48} />
    </Container>
  )
}
