import { FC } from "react"
import * as S from "./styles"

interface TitleProps {
  size: "lg" | "md" | "sm" | "xs"
  label: string
  weight?: "bold" | "semibold" | "regular" | "medium"
}

export const Title: FC<TitleProps> = ({ label, size, weight = "bold" }) => {
  return (
    <S.Title $fontSize={size} $weight={weight}>
      {label}
    </S.Title>
  )
}
