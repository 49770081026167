/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useContext, useEffect, useState } from "react"
import { SensorsConfigContext } from "../../../../../pages/SensorsConfig/context/SensorConfigContext"
import { SensorsConfigContextDTO } from "../../../../../pages/SensorsConfig/entities/SensorsConfigContextDTO"
import { GetTextFromConfigsDTO } from "../../../../../pages/SensorsConfig/entities/GetTextFromConfigsDTO"
import { ArrayTransformSensReturn } from "../../../../../pages/SensorsConfig/entities/ArrayTransformSensReturnDTO"
import { GetDropdownOptionsDTO } from "../../../../../pages/SensorsConfig/entities/GetDropdownOptionsDTO"
import { toast } from "react-toastify"
import { HDR_SERVICES_TYPE } from "hdr-process-data"
import { ConfigObjectDTO } from "../../../../../pages/SensorsConfig/entities/ConfigObjectDTO"
import { sendConfig } from "../../../../../store/features/sensors/sensors.utilities"
import { AlgorithmsDTO } from "../../../../../pages/SensorsConfig/entities/AlgorithmsFormattedDTO"
import { SensorProv } from "../../../../../store/features/sensors/sensors.interfaces"
import { cleanAndPadSerialNumber } from "../../../../../utils/cleanAndPadSerialNumber"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"

export const useServiceConfigController = () => {
  const {
    RIGHT_POSITION_OF_SENSORS_SERVICES_CONFIGS,
    configServiceSelected,
    typeOfServiceSelected,
    transformHowTextWillBePresented,
    _capitalize,
    sensorsToBeDisplayed,
    bleHdrMac,
    setBtnStatus,
    btnStatus,
    setUpdatedSensorInfoSelected,
    updatedSensorInfoSelected,
    configServiceExtraInformation,
    copyLastConfigServiceSelected,
    removeLastDataPOTSalved,
    handleACKTimeout,
  } = useContext(SensorsConfigContext) as SensorsConfigContextDTO

  const { sensorsConfig } = useAppTranslate()

  const [openDropdown, setOpenDropdown] = useState<boolean>(false)
  const [keyState, setKeyState] = useState<number | null>(null)
  const [updateDropdown, setUpdateDropdown] = useState<boolean>(false)
  const [openDropdownCopyConfig, setOpenDropdownCopyConfig] =
    useState<boolean>(false)
  const [sensorToCopyConfig, setSensorToCopyConfig] = useState<string>("")

  const GET_OBJECT_FOR_ALGORITHM_TO_BE_CONFIGURED = {
    rmms: {
      axis: "",
      freqHigh: "",
      freqLow: "",
      nse: "",
      nsCalc: "",
      res: "",
      sens: "",
      tsa: "",
    },
    rms2: {
      axis: "",
      freq: "",
      nse: "",
      nsCalc: "",
      res: "",
      sens: "",
      tsa: "",
    },
    ntc: {
      channel: "",
      res: "",
      tsa: "",
      nse: "",
    },
    _4t20: {
      channel: "",
      res: "",
      nse: "",
      tsa: "",
    },
    pot: {
      channel: "",
      res: "",
      nse: "",
      tsa: "",
    },
    temp: {
      nse: "",
      res: "",
      tsa: "",
    },
    fft: {
      axis: "",
      freq: "",
      nsCalc: "",
      res: "",
      sens: "",
      tsa: "",
    },
    accRaw: {
      axis: "",
      freq: "",
      nsCalc: "",
      res: "",
      sens: "",
      tsa: "",
    },
    tilt: {
      axis: "",
      freq: "",
      nse: "",
      nsCalc: "",
      res: "",
      sens: "",
      tsa: "",
    },
  }

  const GET_TEXT_FROM_CONFIGS: GetTextFromConfigsDTO = {
    axis: sensorsConfig.axle,
    sens: sensorsConfig.sensitivity,
    res: sensorsConfig.resolution,
    freq: sensorsConfig.frequency,
    nsCalc: sensorsConfig.sample_number,
    nse: sensorsConfig.shipping_acquisitions,
    freqLow: sensorsConfig.freqLow,
    freqHigh: sensorsConfig.freqHigh,
    channel: sensorsConfig.channel,
    tsa: sensorsConfig.time_between_acquisitions,
  }

  const GET_TOOLTIP_TEXT: GetTextFromConfigsDTO = {
    axis: "Eixo de medição",
    sens: "Sensibilidade do sensor",
    res: "Resolução do sensor",
    freq: "Frequência do sensor",
    nsCalc:
      "Tamanho do buffer de aceleração utilizado para gerar o resultado do algoritmo (Number of Samples for Calculation)",
    nse: "Quantidade de aquisições por envio",
    freqLow: "Frequência de corte inferior",
    freqHigh: "Frequência de corte superior",
    channel: "Canal",
    tsa: "Tempo entre amostras",
    copy: "Copie configuração de outro sensor com o mesmo serviço já configurado",
  }

  const MIN_TSA_PER_ALGORITHM = {
    temp: 1,
    rms2: 1,
    rmms: 1,
    tilt: 1,
    fft: 30,
    accRaw: 30,
    _4t20: 1,
    ntc: 1,
    pot: 1,
  }

  const handleOpenDropdownCopyConfig = (
    e: React.ChangeEvent<HTMLSelectElement>,
    key: number,
    macSensorCopy: string
  ): void => {
    e.stopPropagation()
    setKeyState(key)
    setOpenDropdownCopyConfig(!openDropdownCopyConfig)
    const index = sensorsToBeDisplayed.findIndex(
      (sensor) => sensor.bleHdrMac === macSensorCopy
    )
    copyConfigDiplay(sensorsToBeDisplayed[index])
  }

  useEffect(() => {
    const options = configServiceSelected.current || ({} as AlgorithmsDTO)
    let fieldsAltered = ""

    /** Updates TSA dropdown */
    let tsa = options.tsa
    if (tsa) {
      tsa = transformHowTextWillBePresented("tsa", tsa) as string
      if (!GET_DROPDOWN_OPTIONS_BY_INFO_TYPE["tsa"].includes(tsa)) {
        options.tsa = GET_DROPDOWN_OPTIONS_BY_INFO_TYPE[
          "tsa"
        ][0]?.toString() as string
        fieldsAltered += "Tempo entre as aquisições "
      }
    }

    /** Updates nsCalc dropdown */
    const nsCalc = options.nsCalc
    if (
      nsCalc &&
      !GET_DROPDOWN_OPTIONS_BY_INFO_TYPE["nsCalc"].includes(Number(nsCalc))
    ) {
      const nsCalcLength = GET_DROPDOWN_OPTIONS_BY_INFO_TYPE["nsCalc"].length
      options["nsCalc"] =
        GET_DROPDOWN_OPTIONS_BY_INFO_TYPE["nsCalc"][nsCalcLength - 1]
      if (fieldsAltered !== "") fieldsAltered += "e Nº de amostras "
      else fieldsAltered += "Nº de amostras "
    }

    if (fieldsAltered !== "") {
      fieldsAltered += "alterado(s)."
      toast.warn(fieldsAltered, {
        autoClose: 3500,
        style: { fontSize: "1.5rem" },
      })
    }

    configServiceSelected.current = options
    setUpdateDropdown(!updateDropdown)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedSensorInfoSelected])

  const handleOpenDropdown = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    key: number
  ): void => {
    e.stopPropagation()
    setKeyState(key)
    setOpenDropdown(!openDropdown)
  }

  const _getTSAArrayFromMinTSA = (
    tsaNumbersArray: number[],
    minTsa: number
  ): {
    tsaNumbersArray: number[]
    tsaStringArray: (string | number | ArrayTransformSensReturn[] | undefined)[]
  } => {
    const tsaArray = tsaNumbersArray.filter((item) => item >= minTsa)
    const tsaStringArray = tsaArray.map((item) =>
      transformHowTextWillBePresented("tsa", item)
    )
    return {
      tsaNumbersArray: tsaArray,
      tsaStringArray,
    }
  }

  const getMinTSAFromNsCalcAndFreq = (
    nsCalc: number,
    freq: number | string
  ): number => {
    if (typeof freq === "string") freq = parseFloat(freq.replace("Hz", ""))
    return Math.ceil(nsCalc / freq)
  }

  const getAxis = () => {
    if (typeOfServiceSelected?.current === "tilt") {
      return ["X", "Y", "Z"]
    }

    return ["X", "Y", "Z", "All"]
  }

  const getNsCalc = () => {
    const nsCalcArray = [32, 64, 128, 256, 512, 1024, 2048]

    const axis = String(configServiceSelected.current?.axis).toLowerCase()
    if (!axis || axis !== "all") return nsCalcArray

    if (typeOfServiceSelected?.current === "accRaw") {
      const res = configServiceSelected.current?.res
      const axis = configServiceSelected.current?.axis
      if (res === "16 bits" && axis === "all")
        return nsCalcArray.slice(0, nsCalcArray.length - 2)
      if (!res || res === "8 bits") return nsCalcArray
      return nsCalcArray.slice(0, nsCalcArray.length - 2)
    }

    return nsCalcArray.slice(0, nsCalcArray.length - 1)
  }

  const getTSA = () => {
    /**
     * 1s, 2s, 5s, 10s, 20s, 30s, 1m, 2m, 5m, 10m, 20m, 30m, 1h, 2h, 6h, 12h
     */
    let tsaNumbersArrayBackup = [
      1, 2, 5, 10, 20, 30, 60, 120, 300, 600, 1200, 1800, 3600, 7200, 21600,
      43200,
    ]

    const nse = configServiceSelected.current?.nse
    if (nse === 1) tsaNumbersArrayBackup = tsaNumbersArrayBackup.slice(2)
    if (nse === 2 || nse === 3)
      tsaNumbersArrayBackup = tsaNumbersArrayBackup.slice(1)

    const minTsaPerAlgorithm =
      MIN_TSA_PER_ALGORITHM[
        typeOfServiceSelected?.current as keyof typeof MIN_TSA_PER_ALGORITHM
      ]
    const { tsaNumbersArray, tsaStringArray } = _getTSAArrayFromMinTSA(
      tsaNumbersArrayBackup,
      minTsaPerAlgorithm
    )

    let freq = configServiceSelected.current?.freq
    const nsCalc = configServiceSelected.current?.nsCalc
    const freqHigh = configServiceSelected.current?.freqHigh
    const freqLow = configServiceSelected.current?.freqLow

    if (freqHigh && freqLow) freq = 3200 // RMMS

    if (!freq || !nsCalc) return tsaStringArray

    const minTsa = getMinTSAFromNsCalcAndFreq(Number(nsCalc), freq)
    const tsaArrays = _getTSAArrayFromMinTSA(tsaNumbersArray, minTsa)
    return tsaArrays.tsaStringArray
  }

  const onDropOptionClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    config:
      | "axis"
      | "res"
      | "sens"
      | "freqHigh"
      | "freqLow"
      | "nsCalc"
      | "nse"
      | "tsa"
      | "freq"
      | "channel",
    dropOption: string
  ): void => {
    e.stopPropagation()

    if (!configServiceSelected?.current) return
    const options = { ...configServiceSelected.current }
    options[config] = dropOption
    configServiceSelected.current = options

    setUpdatedSensorInfoSelected(!updatedSensorInfoSelected)
    setOpenDropdown(!openDropdown)
  }

  const handleUpdateLimitExtraInformation = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: "upperLimit" | "inferiorLimit" | "unitOfMeasurement",
    value: string
  ) => {
    e.stopPropagation()

    const options = { ...configServiceExtraInformation.current }

    if (type === "unitOfMeasurement") {
      options[type] = value
      configServiceExtraInformation.current = options
      return
    }

    options[type] = Number(value)
    configServiceExtraInformation.current = options
  }

  const getSens = () => {
    if (typeOfServiceSelected?.current === "rmms") {
      return ["2g-40mm/s", "4g-80mm/s", "8g-160mm/s"]
    }

    return ["2g", "4g", "8g"]
  }

  const GET_DROPDOWN_OPTIONS_BY_INFO_TYPE: GetDropdownOptionsDTO = {
    axis: getAxis(),
    sens: getSens(),
    res: ["8 bits", "16 bits"],
    freq: [
      "0.781Hz",
      "1.563Hz",
      "3.125Hz",
      "6.25Hz",
      "12.5Hz",
      "25Hz",
      "50Hz",
      "100Hz",
      "200Hz",
      "400Hz",
      "800Hz",
      "1600Hz",
      "3200Hz",
      "6400Hz",
      "12800Hz",
      "25600Hz",
    ],
    nsCalc: getNsCalc(),
    nse: "Quantidade de aquisições por envio", // this needs to be bounded by tsa and frequency
    freqLow: ["1Hz", "2Hz", "5Hz", "10Hz"],
    freqHigh: ["500Hz", "1000Hz", "1600Hz"],
    channel: ["A", "B", "AB"],
    tsa: getTSA(),
  }

  const onNSampleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    config:
      | "axis"
      | "res"
      | "sens"
      | "freqHigh"
      | "freqLow"
      | "nsCalc"
      | "nse"
      | "tsa"
      | "freq"
      | "channel",
    value: string | string
  ): void => {
    e.stopPropagation()

    if (!configServiceSelected?.current) return
    const options = { ...configServiceSelected.current }
    options[config] = Number(value)
    configServiceSelected.current = options
    setUpdatedSensorInfoSelected(!updatedSensorInfoSelected)
  }

  const sanitizeTSA = (value: string): number => {
    const string = value.toString()
    switch (true) {
      case string.includes("s"):
        return Number(string.replace("s", ""))
      case string.includes("m"):
        return Number(string.replace("m", "")) * 60
      case string.includes("h"):
        return Number(string.replace("h", "")) * 3600
      default:
        return Number(value)
    }
  }

  const _sanitizeFields = (key: string, value: string): string | number => {
    switch (key) {
      case "channel":
        return value.toString().toUpperCase()
      case "axis":
        return _capitalize(value.toString())
      case "sens":
        return value.replace(/g.*/, "")
      case "res":
        return value.toString().replace(" bits", "")
      case "freq":
        return value.toString().replace("Hz", "")
      case "freqHigh":
        return value.toString().replace("Hz", "")
      case "freqLow":
        return value.toString().replace("Hz", "")
      case "tsa":
        return sanitizeTSA(value)
      case "nse":
        return Number(value)
      default:
        return value
    }
  }

  const changedConfiguration = () => {
    // If copyLastConfigServiceSelected.current is null or undefined, return true indicating changes
    if (!copyLastConfigServiceSelected.current) return true

    // If copyLastConfigServiceSelected.current is not null or undefined and has properties, return true indicating changes
    if (Object.keys(copyLastConfigServiceSelected.current).length === 0)
      return true

    // Iterate through each key in configServiceSelected.current
    for (const key in configServiceSelected.current) {
      // Ignore properties that do not exist on both objects
      if (!(key in copyLastConfigServiceSelected.current)) {
        continue
      }
      if (
        configServiceSelected.current[key as keyof AlgorithmsDTO] !==
        copyLastConfigServiceSelected.current[key as keyof AlgorithmsDTO]
      ) {
        return true // there was a change
      }
    }

    // If no changes were detected, return false
    return false
  }

  // Verify if all fields are filled in POT and 4T20 (upperLimit, inferiorLimit and unitOfMeasurement)
  const _verifyAllExtraFieldsAreFilled = () => {
    if (
      !configServiceExtraInformation.current ||
      !configServiceExtraInformation.current.upperLimit ||
      configServiceExtraInformation.current.inferiorLimit == null ||
      !configServiceExtraInformation.current.unitOfMeasurement
    ) {
      toast.warn("Todos os campos precisam ser preenchidos!", {
        style: { fontSize: "1.5rem" },
      })
      return false
    }
    return true
  }

  const _verifyIfAllFieldsAreFilled = () => {
    if (!typeOfServiceSelected.current || !configServiceSelected.current)
      return undefined

    const objectToBeConfiguredOrReconfiguredPattern =
      GET_OBJECT_FOR_ALGORITHM_TO_BE_CONFIGURED[typeOfServiceSelected.current]
    const objectToConfOrReconfigure = {} as any

    if (Object.keys(objectToBeConfiguredOrReconfiguredPattern).length > 0) {
      for (
        let index = 0;
        index < Object.keys(objectToBeConfiguredOrReconfiguredPattern).length;
        index++
      ) {
        const option = Object.keys(objectToBeConfiguredOrReconfiguredPattern)[
          index
        ]

        if (
          !configServiceSelected.current[
            option as
              | "axis"
              | "res"
              | "sens"
              | "freqHigh"
              | "freqLow"
              | "nsCalc"
              | "nse"
              | "tsa"
              | "freq"
              | "channel"
          ]
        ) {
          toast.warn("Todos os campos precisam ser preenchidos!", {
            style: { fontSize: "1.5rem" },
          })
          return undefined
        }

        if (
          typeOfServiceSelected.current === "pot" ||
          typeOfServiceSelected.current === "_4t20"
        ) {
          if (!_verifyAllExtraFieldsAreFilled()) return undefined
          if (!changedConfiguration()) {
            savesExtraInformationInLS(
              bleHdrMac as string,
              typeOfServiceSelected.current as "_4t20" | "pot"
            )
            removeLastDataPOTSalved(bleHdrMac as string)
            return undefined
          }
        }

        objectToConfOrReconfigure[option] = _sanitizeFields(
          option,
          String(
            configServiceSelected.current[
              option as
                | "axis"
                | "res"
                | "sens"
                | "freqHigh"
                | "freqLow"
                | "nsCalc"
                | "nse"
                | "tsa"
                | "freq"
                | "channel"
            ]
          )
        )
      }
    }
    return objectToConfOrReconfigure
  }

  const onConfigureReconfigureButtonClick = async () => {
    const objectToConfOrReconfigure = _verifyIfAllFieldsAreFilled()
    if (
      !objectToConfOrReconfigure ||
      !bleHdrMac ||
      !typeOfServiceSelected.current
    )
      return
    const infoOfSensorWithMac = sensorsToBeDisplayed.find(
      (sensorObj) => sensorObj.bleHdrMac === bleHdrMac.toString()
    )
    if (!infoOfSensorWithMac) return

    const configObject: ConfigObjectDTO = {
      network: infoOfSensorWithMac.bleNetwork,
      mac: infoOfSensorWithMac.bleHdrMac,
      config: {
        serviceType: HDR_SERVICES_TYPE[typeOfServiceSelected.current],
        serviceMode: 1, // <- maybe will have to change at some time in the future
        ...objectToConfOrReconfigure,
      },
    }
    if (
      typeOfServiceSelected.current === "pot" ||
      typeOfServiceSelected.current === "_4t20"
    ) {
      savesExtraInformationInLS(
        bleHdrMac,
        typeOfServiceSelected.current as "_4t20" | "pot"
      )
    }
    setBtnStatus("configuring")

    const result = await sendConfig(configObject)

    if (result.success) {
      handleACKTimeout(() => {
        setBtnStatus("error")
      })
    } else {
      toast.error(result.message, {
        autoClose: 3500,
        style: { fontSize: "1.5rem" },
      })
      setBtnStatus("error")
    }
  }

  useEffect(() => {
    copyLastConfigServiceSelected.current = configServiceSelected.current
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const savesExtraInformationInLS = (
    bleHdrMac: string,
    service: "pot" | "_4t20"
  ) => {
    const extraInformation = configServiceExtraInformation.current

    localStorage.setItem(
      `${bleHdrMac} - ${service} - ExtraConfig`,
      JSON.stringify(extraInformation)
    )
    setBtnStatus("configured")
  }

  const copyConfigDiplay = (sensor: SensorProv) => {
    if (sensor && typeOfServiceSelected.current) {
      const sensorToCopy = sensor[typeOfServiceSelected.current]
      if (sensorToCopy) {
        configServiceSelected.current = sensorToCopy
        setUpdatedSensorInfoSelected(!updatedSensorInfoSelected)
      }
    }
  }

  const handleCopyConfig = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    sensorData: SensorProv
  ) => {
    e.stopPropagation()
    copyConfigDiplay(sensorData)
    setSensorToCopyConfig(
      sensorData.name
        ? sensorData.name
        : `S${cleanAndPadSerialNumber(sensorData.sensor.productionSerialNumber, sensorData.sensor.hardwareVersion)}`
    )
    setOpenDropdownCopyConfig(false)
  }

  const handleChange = (
    e: ChangeEvent<HTMLSelectElement>,
    config: string,
    dropOption: string
  ): void => {
    e.stopPropagation()
    const options = { ...configServiceSelected.current }
    options[config as keyof typeof options] = dropOption
    configServiceSelected.current = options as AlgorithmsDTO
    setUpdatedSensorInfoSelected(!updatedSensorInfoSelected)
  }
  return {
    RIGHT_POSITION_OF_SENSORS_SERVICES_CONFIGS,
    GET_TOOLTIP_TEXT,
    GET_TEXT_FROM_CONFIGS,
    configServiceSelected,
    typeOfServiceSelected,
    transformHowTextWillBePresented,
    onNSampleInputChange,
    GET_DROPDOWN_OPTIONS_BY_INFO_TYPE,
    onDropOptionClick,
    openDropdown,
    setOpenDropdown,
    handleOpenDropdown,
    keyState,
    onConfigureReconfigureButtonClick,
    btnStatus,
    sensorToCopyConfig,
    handleOpenDropdownCopyConfig,
    openDropdownCopyConfig,
    sensorsToBeDisplayed,
    handleCopyConfig,
    handleChange,
    handleUpdateLimitExtraInformation,
    bleHdrMac,
    configServiceExtraInformation,
  }
}
