import { AssetDescription } from "../../../components/Description"
import { AssetProperties } from "../../../components/Properties"
import { AssetType } from "../../../components/Type"
import { Divider } from "../../../../../../components/ui/Divider"
import { Button } from "../../../../../../components/ui/Button"
import { formatedDate } from "../../../../../../utils/date"
import { Container, SensorContainer } from "./styles"
import { MessageContainer } from "../../styles"
import { AssetWithSensor } from "../../../components/AssetWithSensor"
import { AssetWithoutSensor } from "../../../components/AssetWithoutSensor"
import { AssetDetailsSkeleton } from "./Details.skeleton"
import { useFetchAssetDetailsQuery } from "../../../../../../store/store"
import { useAppTranslate } from "../../../../../../translate/useAppTranslate"

interface AsssetDetailsProps {
  assetId: number
}

const RenderAssetDetails = ({ assetId }: AsssetDetailsProps) => {
  const { assets, buttons } = useAppTranslate()

  const { data, isError, isFetching, isLoading, refetch } =
    useFetchAssetDetailsQuery(assetId)

  if (isFetching || isLoading) return <AssetDetailsSkeleton />

  if (isError || !data)
    return (
      <MessageContainer>
        {assets.details.error}
        <Button variant='link' onClick={refetch}>
          {buttons.reload}
        </Button>
      </MessageContainer>
    )

  const { description, name, type, createdAt, sensor, properties } = data

  return (
    <>
      <AssetDescription
        description={description}
        assetName={name}
        assetId={assetId}
      />

      <Divider />

      <AssetType assetType={type} createdAt={formatedDate(createdAt)} />

      <Divider />

      <AssetProperties properties={properties} />

      <Divider />

      <SensorContainer>
        {sensor && Object.keys(sensor).length !== 0 ? (
          <AssetWithSensor
            servicesConfigured={sensor.services}
            sensorName={sensor.name}
            associatedAt={sensor.associatedAt}
            assetId={assetId}
            assetName={name}
          />
        ) : (
          <AssetWithoutSensor assetId={assetId} />
        )}
      </SensorContainer>
    </>
  )
}

const AssetDetails = ({ assetId }: AsssetDetailsProps) => {
  return (
    <Container>
      <RenderAssetDetails assetId={assetId} />
    </Container>
  )
}

export { AssetDetails, AssetDetailsSkeleton }
