import { Skeleton } from "@mui/material"
import { TitleAndTextSkeleton } from "../../../../../../components/TitleAndText"
import {
  AssetHeader,
  Configurations,
  Container,
  ImageAndDescription,
} from "../styles"
import { ImageContainerSkeleton } from "../../ImageContainer"

export const AssetDescriptionSkeleton = () => {
  return (
    <Container>
      <AssetHeader>
        <Skeleton variant='rounded' width={150} />
        <Configurations>
          <Skeleton variant='rounded' width={80} height={40} />
          <Skeleton variant='rounded' width={35} height={30} />
        </Configurations>
      </AssetHeader>
      <ImageAndDescription>
        <ImageContainerSkeleton />
        <TitleAndTextSkeleton />
      </ImageAndDescription>
    </Container>
  )
}
