import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { thorBaseQuery } from "../utils"
import {
  AssetData,
  AssetDetailsData,
  AssetTreeData,
} from "../../../utils/entities/assets"
import {
  transformAssetDetails,
  transformAssetsChildren,
  transformAssetsStructure,
} from "./transforms"
import { CreateAssetData, LinkSensorData, UpdateAssetData } from "./entities"
import {
  onCreateQueryStarted,
  onDeleteQueryStarted,
  onUpdateQueryStarted,
} from "./updates"

export const assetsApi = createApi({
  reducerPath: "assetsApi",
  baseQuery: thorBaseQuery(),
  endpoints: (build) => ({
    fetchAssets: build.query<AssetTreeData, void>({
      query: () => {
        return {
          url: "/v1/assets/structure",
          method: "GET",
        }
      },
      transformResponse: transformAssetsStructure,
    }),

    fetchAssetChildren: build.query<AssetData[] | undefined, number>({
      query: (_) => {
        return {
          url: `/v1/assets/structure`,
          method: "GET",
        }
      },
      transformResponse: transformAssetsChildren,
    }),

    fetchAssetDetails: build.query<AssetDetailsData, number>({
      query: (assetId) => {
        return {
          url: `/v1/assets/${assetId}`,
          method: "GET",
        }
      },
      transformResponse: transformAssetDetails,
    }),

    createAsset: build.mutation<AssetDetailsData, CreateAssetData>({
      query: ({ parentId, data }) => {
        return {
          url: "/v1/assets",
          method: "POST",
          body: {
            properties: Object.fromEntries(
              data.properties.map(({ property, value }) => [property, value])
            ),
            assetSourceId: parentId,
            description: data.description,
            name: data.name,
            sensorProvId: data.sensorId ? Number(data.sensorId) : undefined,
            typeId: Number(data.type),
          },
        }
      },
      transformResponse: transformAssetDetails,
      onQueryStarted: onCreateQueryStarted,
    }),

    deleteAsset: build.mutation<void, number>({
      query: (assetId) => {
        return {
          url: `/v1/assets/${assetId}`,
          method: "DELETE",
        }
      },
      onQueryStarted: onDeleteQueryStarted,
    }),

    updateAsset: build.mutation<AssetDetailsData, UpdateAssetData>({
      query: ({ id, data }) => {
        return {
          url: `/v1/assets/${id}`,
          method: "PATCH",
          body: {
            name: data.name,
            description: data.description,
          },
        }
      },
      onQueryStarted: onUpdateQueryStarted,
      transformResponse: transformAssetDetails,
    }),

    linkSensor: build.mutation<void, LinkSensorData>({
      query: ({ id, sensorId }) => {
        return {
          url: `/v1/assets/${id}/sensors`,
          method: "POST",
          body: {
            sensorProvId: sensorId,
          },
        }
      },

      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        await queryFulfilled

        dispatch(
          assetsApi.util.updateQueryData(
            "fetchAssetDetails",
            request.id,
            (draft) => {
              draft.sensor = {
                id: request.sensorId,
                associatedAt: 1,
                name: "ID" + request.sensorId,
                serialNumber: "SN" + request.sensorId,
                services: [],
              }
            }
          )
        )
      },
    }),

    deleteLinkSensor: build.mutation<void, LinkSensorData>({
      query: ({ id, sensorId }) => {
        return {
          url: `/v1/assets/${id}/sensors/${sensorId}`,
          method: "DELETE",
        }
      },
    }),
  }),
})

export const {
  useFetchAssetDetailsQuery,
  useFetchAssetsQuery,
  useCreateAssetMutation,
  useDeleteAssetMutation,
  useUpdateAssetMutation,
  useLinkSensorMutation,
  useFetchAssetChildrenQuery,
  useDeleteLinkSensorMutation,
} = assetsApi
