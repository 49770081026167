import { NewHeader } from "../../../components/NewHeader"
import { useAppTranslate } from "../../../translate/useAppTranslate"

import { ModalRename } from "./components/ModalRename"
import { useGatewayController } from "./controllers/gatewayController"
import { GatewayContent } from "./components/GatewayContent"
import { Content, Wrapper } from "./styles"

export const GatewaysPage = () => {
  const { lateralMenu } = useAppTranslate()

  const page = {
    title: lateralMenu.routes.gateways,
  }

  const {
    isOpenModalRename,
    handleCloseModal,
    renameGateway,
    handleEditNameGateway,
    isLoading,
    handleOpenModal,
    setSelectedItems,
    listGateways,
    isError,
    isFetching,
    refetch,
  } = useGatewayController()

  return (
    <Wrapper>
      <NewHeader page={page} />

      <Content>
        <GatewayContent
          handleOpenModal={handleOpenModal}
          setSelectedItems={setSelectedItems}
          listGateways={listGateways}
          error={isError}
          isFetching={isFetching}
          refetch={refetch}
        />
      </Content>
      {isOpenModalRename && (
        <ModalRename
          handleCloseModal={handleCloseModal}
          renameGateway={renameGateway}
          handleEditNameGateway={handleEditNameGateway}
          isLoading={isLoading}
        />
      )}
    </Wrapper>
  )
}
