import i18n from "../translate/i18n"

export function errorMessage(statusCode: number): string {
  const errorKey = `errors.${statusCode}`

  const errorMessage = i18n.t(errorKey)

  if (errorMessage === errorKey) {
    return i18n.t("errors.default", { statusCode })
  }

  return errorMessage
}
