/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect } from "react"
import { SensorsConfigContext } from "../context/SensorConfigContext"
import { SensorsConfigContextDTO } from "../entities/SensorsConfigContextDTO"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { HDR_RQM_ACTION } from "../../../utils/hdr_rqm_action"
import { HDR_RQM_ACK } from "../../../utils/hdr_rqm_ack"
import {
  fetchSensorsConfig,
  fetchSensorsProv,
} from "../../../store/features/sensors/sensors.api"

const useSensorsConfigController = () => {
  const {
    sensorsConfigured,
    sensorsProvisioned,
    sensorsToBeDisplayed,
    disablePageRef,
    requestTimeRef,
    setBtnStatus,
    setBtnStatusDisable,
    setBtnStatusReset,
    removeLastDataPOTSalved,
    bleHdrMac,
  } = useContext(SensorsConfigContext) as SensorsConfigContextDTO

  const socket = useAppSelector((state) => state.persistedReducer.socket.socket)
  const companyId = useAppSelector(
    (state) => state.persistedReducer.user.profile?.user_data.x1
  )
  const dispatch = useAppDispatch()

  const wsAckEvent = {
    ACK: `WS2CLIENT/ACK/${companyId}`,
    MsgType2: `WS2CLIENT/REQUISITION_AVAILABLE/${companyId}`,
  }

  const clearSocketListeners = useCallback(() => {
    Object.values(wsAckEvent).forEach((eventName) => {
      socket?.removeListener(eventName)
    })
  }, [wsAckEvent, socket])

  /** Updates sensors upon receiving confirmation ACK*/
  const handleDispacthConfiguredSensors = (hdr_ack: number) => {
    dispatch(fetchSensorsConfig())
    hdr_ack === HDR_RQM_ACK.RQM_ACK_RSTOK && dispatch(fetchSensorsProv())
  }

  /** Receives the ACK via websocket that verifies success or failure in the request to the sensor */
  const registerAckSocketEvent = useCallback(() => {
    socket?.on(wsAckEvent.ACK, (data: { action: number; ack: number }) => {
      if (!disablePageRef.current) return

      if (
        data.action !== HDR_RQM_ACTION.RQM_ACTION_CONFIG &&
        data.action !== HDR_RQM_ACTION.RQM_ACTION_RESET
      )
        return

      if (Number(data.ack) === 1) return

      switch (Number(data.ack)) {
        case HDR_RQM_ACK.RQM_ACK_CFGOK:
          _clearVariables()
          setTimeout(() => {
            handleDispacthConfiguredSensors(HDR_RQM_ACK.RQM_ACK_CFGOK)
            setBtnStatus("configured")
            removeLastDataPOTSalved(bleHdrMac as string)
            setBtnStatusDisable("disabled")
          }, 2000)
          break

        case HDR_RQM_ACK.RQM_ACK_RSTOK:
          _clearVariables()
          setTimeout(() => {
            handleDispacthConfiguredSensors(HDR_RQM_ACK.RQM_ACK_RSTOK)
            setBtnStatusReset("reseted")
          }, 2000)
          break

        case HDR_RQM_ACK.RQM_ACK_TIMEOUT:
          setBtnStatusReset("error")
          setBtnStatusDisable("error")
          setBtnStatus("error")
          _clearVariables()
          break

        default:
          break
      }
    })
  }, [wsAckEvent, socket])

  const _clearVariables = (): void => {
    requestTimeRef.current = null
    disablePageRef.current = false
  }

  useEffect(() => {
    if (socket === null) return

    registerAckSocketEvent()

    return function cleanup() {
      clearSocketListeners()
    }

    // eslint-disable-next-line
  }, [socket, registerAckSocketEvent, clearSocketListeners])

  return {
    sensorsConfigured,
    sensorsProvisioned,
    sensorsToBeDisplayed,
  }
}

export default useSensorsConfigController
