import styled, { css } from "styled-components"

export const Switch = styled.label<{ $status: boolean }>`
  display: flex;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.palette.error.main};

  ${({ $status }) =>
    $status &&
    css`
      background-color: ${(props) => props.theme.palette.success.main};
    `}
`
