import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 2px;
  width: 100%;
`

export const Input = styled.input<{ $empty: boolean }>`
  width: 300px;
  height: 48px;

  border: 1px solid
    ${({ $empty, theme }) =>
      $empty ? theme.palette.gray[500] : theme.palette.gray[400]};

  border-radius: 4px;

  padding: 0 12px;

  color: ${({ $empty, theme }) =>
    $empty ? theme.palette.gray[500] : theme.palette.gray[800]};
`
