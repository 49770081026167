/* eslint-disable react/prop-types */
import {
  ChangeEvent,
  ReactNode,
  createContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { SensorsConfigContextDTO } from "../entities/SensorsConfigContextDTO"
import { HealthDTO } from "../../../utils/entities/sensors/services/healthDTO"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { SensorProv } from "../../../store/features/sensors/sensors.interfaces"
import { RightPositionOfSensorsServicesConfigsDTO } from "../entities/RightPositionOfSensorsServicesConfigsDTO"
import { AlgorithmsDTO } from "../entities/AlgorithmsFormattedDTO"
import { ArrayTransformSensReturn } from "../entities/ArrayTransformSensReturnDTO"
import { closeModal } from "../../../store/features/modal/modal.slice"
import { GetTooltipTextByAlgorithmDTO } from "../entities/GetTooltipTextByAlgorithmDTO"
import { ResetResquestDTO } from "../entities/ResetRequestDTO"
import { useAppTranslate } from "../../../translate/useAppTranslate"
import { ConfigExtraDTO } from "../entities/ConfigExtraDTO"
import { LastPotDataSavedDTO } from "../entities/LastPotDataSavedDTO"
import { filterAndOrderSensors } from "../services/filter"

interface SensorsConfigProviderProps {
  children: ReactNode
}

export const SensorsConfigContext =
  createContext<SensorsConfigContextDTO | null>(null)

export const SensorsConfigProvider: React.FC<SensorsConfigProviderProps> = ({
  children,
}) => {
  const [healths, setHealths] = useState([] as HealthDTO[])
  const sensorsConfigured = useAppSelector(
    (state) => state.persistedReducer.sensors.config
  )
  const sensorsProvisioned = useAppSelector(
    (state) => state.persistedReducer.sensors.prov
  )
  const [sensorsToBeDisplayed, setSensorToBeDisplayed] = useState(
    [] as SensorProv[]
  )

  const [filteredSensors, setFilteredSensors] = useState<SensorProv[]>([])

  const typeOfServiceSelected = useRef<
    | "rmms"
    | "rms2"
    | "ntc"
    | "_4t20"
    | "pot"
    | "temp"
    | "fft"
    | "accRaw"
    | "tilt"
    | null
  >(null)
  const configServiceSelected = useRef<AlgorithmsDTO>(null)
  const copyLastConfigServiceSelected = useRef<AlgorithmsDTO>(null)
  const [localStorageUpdated, setLocalStorageUpdated] = useState<boolean>(false)

  const updateReferencePOT = () => {
    setLocalStorageUpdated((prevState) => !prevState)
  }
  const configServiceExtraInformation = useRef<ConfigExtraDTO>({
    upperLimit: 100,
    inferiorLimit: 0,
    unitOfMeasurement: "%",
  })

  const [modalType, setModalType] = useState<"configure" | "reconfigure">(
    "configure"
  )
  const [bleHdrMac, setBleHdrMac] = useState<string | null>(null)
  const disablePageRef = useRef<boolean>(false)
  const requestTimeRef = useRef<number | null>(null)
  const [updatedSensorInfoSelected, setUpdatedSensorInfoSelected] =
    useState(false)
  const [btnStatus, setBtnStatus] = useState<
    "configured" | "configure" | "configuring" | "error"
  >("configure")
  const [btnStatusDisable, setBtnStatusDisable] = useState<
    "disable" | "disabling" | "disabled" | "error"
  >("disable")
  const [btnStatusReset, setBtnStatusReset] = useState<
    "reset" | "resetting" | "reseted" | "error"
  >("reset")
  const [openModalConfigService, setOpenModalConfigService] = useState(false)
  const titleModalDisableOrReset = useRef<string>("")

  const dispatch = useAppDispatch()
  const [resetOnlyAlgorithm, setResetOnlyAlgorithm] = useState(false)
  const [resetRequest, setResetRequest] = useState({} as ResetResquestDTO)
  const [lastPOTDataSavedFromWS, setLastPOtDataSavedFromWS] = useState(
    {} as LastPotDataSavedDTO
  )
  const {
    sensorsConfig: { services },
  } = useAppTranslate()

  const RIGHT_POSITION_OF_SENSORS_SERVICES_CONFIGS: RightPositionOfSensorsServicesConfigsDTO =
    {
      rmms: [
        "axis",
        "res",
        "sens",
        "freqHigh",
        "freqLow",
        "nsCalc",
        "nse",
        "tsa",
      ],
      rms2: ["axis", "res", "sens", "freq", "nsCalc", "nse", "tsa"],
      ntc: ["channel", "res", "nse", "tsa"],
      _4t20: ["channel", "res", "nse", "tsa"],
      pot: ["channel", "res", "nse", "tsa"],
      temp: ["res", "nse", "tsa"],
      fft: ["axis", "res", "sens", "freq", "nsCalc", "tsa"],
      accRaw: ["axis", "res", "sens", "freq", "nsCalc", "tsa"],
      tilt: ["axis", "res", "sens", "freq", "nsCalc", "nse", "tsa"],
    }

  const TRANSLATE_HDR_SERVICE_TYPE_INTO_PLAIN_PORTUGUESE: GetTooltipTextByAlgorithmDTO =
    {
      temp: services.temperature,
      rms2: services.rms2,
      rmms: services.rmms,
      tilt: services.tilt,
      fft: services.fft,
      accRaw: services.accRaw,
      _4t20: services._420mA,
      ntc: services.ntc,
      pot: services.pot,
    }

  const arrayTransformSensReturn: ArrayTransformSensReturn = {
    "2": "2g",
    "4": "4g",
    "8": "8g",
  }

  const arrayTransformSensrReturnRMMS: ArrayTransformSensReturn = {
    "2": "2g-40mm/s",
    "4": "4g-80mm/s",
    "8": "8g-160mm/s",
  }

  const handleACKTimeout = (onTimeout: () => void) => {
    const TIMEOUT_LIMIT = 50 * 1000 // 50 seconds

    disablePageRef.current = true
    requestTimeRef.current = new Date().getTime()

    setTimeout(() => {
      if (!disablePageRef.current || !requestTimeRef.current) return

      let now = new Date().getTime()

      if (now - requestTimeRef.current < TIMEOUT_LIMIT) return

      onTimeout()

      disablePageRef.current = false
      requestTimeRef.current = null
    }, TIMEOUT_LIMIT)
  }

  const _capitalize = (str: string): string => {
    if (str?.length === 1) {
      return str?.toUpperCase()
    }

    const lower = str?.toLowerCase()
    return str?.charAt(0).toUpperCase() + lower?.slice(1)
  }

  const _transformSens = (
    value: number | string,
    serviceName?:
      | "temp"
      | "rms2"
      | "rmms"
      | "tilt"
      | "fft"
      | "accRaw"
      | "_4t20"
      | "ntc"
      | "pot"
  ) => {
    if (typeof value === "string") return value
    if (isNaN(value)) return value
    else if (serviceName === "rmms")
      return arrayTransformSensrReturnRMMS[
        value as keyof ArrayTransformSensReturn
      ]
    else
      return arrayTransformSensReturn[value as keyof ArrayTransformSensReturn]
  }

  const _transformTSA = (value: number | string): string | number => {
    if (typeof value === "string") return value

    if (isNaN(value) || value === 0) return value
    else if (value < 60) {
      return `${value}s`
    } else if (value >= 60 && value < 3600) {
      return `${value / 60}m`
    } else {
      return `${value / 3600}h`
    }
  }

  const _transformFreq = (value: number | string): string | number => {
    if (typeof value === "string") return value
    if (!value || isNaN(value)) return value
    return `${value}Hz`
  }

  const transformHowTextWillBePresented = (
    config: string,
    value: null | number | undefined | string,
    serviceName?:
      | "temp"
      | "rms2"
      | "rmms"
      | "tilt"
      | "fft"
      | "accRaw"
      | "_4t20"
      | "ntc"
      | "pot"
  ): string | number => {
    if (!value) return " "
    switch (true) {
      case config === "axis":
        return _capitalize(String(value))
      case config === "channel":
        return String(value)?.toUpperCase()
      case config === "sens":
        return _transformSens(value, serviceName)
      case config === "tsa":
        return _transformTSA(value)
      case config === "freq" || config === "freqHigh" || config === "freqLow":
        return _transformFreq(value)
      default:
        return value
    }
  }

  const handleCloseConfigServiceModal = () => {
    if (btnStatus !== "configuring") {
      dispatch(closeModal())
      setBtnStatus("configure")
      setBtnStatusDisable("disable")
      setOpenModalConfigService(false)
      configServiceExtraInformation.current = {
        upperLimit: 100,
        inferiorLimit: 0,
        unitOfMeasurement: "%",
      }
    }
  }

  const handleCloseModal = () => {
    dispatch(closeModal())
    setBtnStatusDisable("disable")
    setBtnStatusReset("reset")
    setBtnStatus("configure")
    setOpenModalConfigService(false)
  }

  const handleCloseDisableOrResetModal = () => {
    if (resetOnlyAlgorithm && btnStatusDisable !== "disabling") {
      handleCloseModal()
    }

    if (!resetOnlyAlgorithm && btnStatusReset !== "resetting") {
      handleCloseModal()
    }
  }

  const removeLastDataPOTSalved = (mac: string) => {
    setLastPOtDataSavedFromWS((prevState) => {
      const newState = { ...prevState }
      if (prevState[mac]) {
        delete newState[mac]
      }
      return newState
    })
    localStorage.removeItem(`${mac} - pot - Reference`)
    updateReferencePOT()
  }
  const [orderOption, setOrderOption] = useState<number>(0)
  const [searchValue, setSearchValue] = useState<string>("")
  const [searchOption, setSearchOption] = useState<number>(0)

  const handleChangeOrderOption = (e: ChangeEvent<HTMLSelectElement>) => {
    setOrderOption(Number(e.target.value))
  }

  const handleClickOrderOption = () => {
    setFilteredSensors(
      filterAndOrderSensors(
        sensorsToBeDisplayed,
        searchOption,
        searchValue,
        orderOption
      )
    )
  }

  const handleChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const handleChangeSearchOption = (e: ChangeEvent<HTMLSelectElement>) => {
    setSearchOption(Number(e.target.value))
  }

  useEffect(() => {
    if (filteredSensors.length !== 0) {
      const aux: SensorProv[] = []

      filteredSensors.forEach((sensor) => {
        const find = sensorsToBeDisplayed.find(
          (s) => s.bleHdrMac === sensor.bleHdrMac
        )

        if (find) aux.push(find)
      })

      setFilteredSensors(aux)

      return
    }

    setFilteredSensors(
      filterAndOrderSensors(
        sensorsToBeDisplayed,
        searchOption,
        searchValue,
        orderOption
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensorsToBeDisplayed])

  const handleClearFilter = () => {
    setSearchValue("")
    setSearchOption(0)
  }

  const defaultContext: SensorsConfigContextDTO = {
    healths,
    setHealths,
    sensorsProvisioned,
    sensorsConfigured,
    sensorsToBeDisplayed,
    filteredSensors,
    setSensorToBeDisplayed,
    RIGHT_POSITION_OF_SENSORS_SERVICES_CONFIGS,
    typeOfServiceSelected,
    configServiceSelected,
    copyLastConfigServiceSelected,
    transformHowTextWillBePresented,
    modalType,
    setModalType,
    _capitalize,
    bleHdrMac,
    setBleHdrMac,
    disablePageRef,
    requestTimeRef,
    btnStatus,
    setBtnStatus,
    handleCloseConfigServiceModal,
    updatedSensorInfoSelected,
    setUpdatedSensorInfoSelected,
    TRANSLATE_HDR_SERVICE_TYPE_INTO_PLAIN_PORTUGUESE,
    resetOnlyAlgorithm,
    setResetOnlyAlgorithm,
    resetRequest,
    setResetRequest,
    btnStatusDisable,
    setBtnStatusDisable,
    handleCloseDisableOrResetModal,
    titleModalDisableOrReset,
    btnStatusReset,
    setBtnStatusReset,
    openModalConfigService,
    setOpenModalConfigService,
    configServiceExtraInformation,
    lastPOTDataSavedFromWS,
    setLastPOtDataSavedFromWS,
    removeLastDataPOTSalved,
    localStorageUpdated,
    updateReferencePOT,
    handleChangeOrderOption,
    handleClickOrderOption,
    orderOption,
    searchValue,
    searchOption,
    handleChangeSearchValue,
    handleChangeSearchOption,
    handleClearFilter,
    handleACKTimeout,
  }

  return (
    <SensorsConfigContext.Provider value={defaultContext}>
      {children}
    </SensorsConfigContext.Provider>
  )
}
