import { Container } from "./styles"

interface CellProps {
  children: React.ReactNode
  onClick?: () => void
}

export const Cell = ({ children, onClick }: CellProps) => {
  return <Container onClick={onClick}>{children}</Container>
}
