import { SensorsProvProvider } from "./context/SensorsProvContext"
import { SensorsProvWrapper } from "./view"
import Container from "../../components/ui/Container"
import { NewHeader } from "../../components/NewHeader"
import Row from "../../components/ui/Row"
import styled from "styled-components"
import Column from "../../components/ui/Column"
import { useAppTranslate } from "../../translate/useAppTranslate"

const Card = styled.div`
  height: auto;
  border: solid 1px ${(props) => props.theme.palette.gray[400]};
  border-radius: 6px 6px 6px 6px;
  background-color: ${(props) => props.theme.palette.gray[50]};
  padding: 20px 16px;
`

export const SensorsProv = () => {
  const { lateralMenu } = useAppTranslate()

  const page = {
    title: lateralMenu.routes.sensors.title,
    subTitle: lateralMenu.routes.sensors.prov,
  }

  return (
    <SensorsProvProvider>
      <Row>
        <NewHeader page={page} />
      </Row>
      <Container>
        <Row>
          <Column md='24' lg='24' xl='24'>
            <Card>
              <SensorsProvWrapper />
            </Card>
          </Column>
        </Row>
      </Container>
    </SensorsProvProvider>
  )
}
