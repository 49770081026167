import { Skeleton } from "@mui/material"
import { Content, Header, Actions, Container, Services } from "./styles"

export const AssetWithSensorSkeleton = () => {
  return (
    <Content>
      <Header>
        <Skeleton variant='text' width={80} height={30} />
        <Actions>
          <Skeleton variant='rounded' height={30} width={30} />
          <Skeleton variant='rounded' height={30} width={30} />
        </Actions>
      </Header>
      <Container>
        <Skeleton variant='text' width={120} height={30} />
        <Services>
          {Array.from({ length: 9 }, (_, index) => (
            <Skeleton variant='circular' height={32} width={32} key={index} />
          ))}
        </Services>
      </Container>
    </Content>
  )
}
