import { MutationLifecycleApi } from "@reduxjs/toolkit/dist/query/endpointDefinitions"
import { CreateAssetData, UpdateAssetData } from "./entities"
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/dist/query/react"
import { AssetDetailsData } from "../../../utils/entities/assets"
import { assetsApi } from "./slice"

type BaseQueryFnType = BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
>

type MutationType<Request, Response> = MutationLifecycleApi<
  Request,
  BaseQueryFnType,
  Response,
  "assetsApi"
>

export const onUpdateQueryStarted = async (
  request: UpdateAssetData,
  { dispatch, queryFulfilled }: MutationType<UpdateAssetData, AssetDetailsData>
) => {
  try {
    const response = (await queryFulfilled).data

    dispatch(
      assetsApi.util.updateQueryData("fetchAssets", undefined, (draft) => {
        draft[response.id].data = {
          id: response.id,
          name: response.name,
          typeId: response.type,
        }
      })
    )

    dispatch(
      assetsApi.util.updateQueryData(
        "fetchAssetDetails",
        response.id,
        (draft) => {
          draft.name = response.name
          draft.type = Number(response.type)
          draft.description = response.description
          draft.properties = response.properties ? response.properties : {}
        }
      )
    )
  } catch (error) {
    console.log(error)
  }
}

export const onCreateQueryStarted = async (
  { parentId }: CreateAssetData,
  { dispatch, queryFulfilled }: MutationType<CreateAssetData, AssetDetailsData>
) => {
  try {
    const { id, name, type } = (await queryFulfilled).data

    dispatch(
      assetsApi.util.updateQueryData("fetchAssets", undefined, (draft) => {
        const children = draft[parentId].children ?? []

        draft[parentId].children = [...children, id]
        draft[parentId].isFolder = true

        draft[id] = {
          data: {
            id,
            name,
            typeId: type,
          },
          index: id,
          children: [],
          isFolder: false,
        }
      })
    )
  } catch (error) {
    console.log(error)
  }
}

export const onDeleteQueryStarted = async (
  id: number,
  { dispatch, queryFulfilled }: MutationType<number, void>
) => {
  await queryFulfilled

  dispatch(
    assetsApi.util.updateQueryData("fetchAssets", undefined, (draft) => {
      const keys = Object.keys(draft)

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i]

        if (!draft[key].children) continue
        if (!draft[key].children!.includes(id)) continue

        draft[key].isFolder = draft[key].children!.length > 1

        draft[key].children = draft[key].children!.filter(
          (child) => child !== id
        )
      }

      delete draft[id]
    })
  )
}
