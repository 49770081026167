import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { GatewayLogDTO } from "../../pages/Gateways/entities/gatewaysLogDTO"
import SocketFactory, { ISocket } from "../../services/SocketFactory"

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl: "/ws-gateway" }),
  endpoints: (build) => ({
    getMessages: build.query<GatewayLogDTO[], string>({
      queryFn: () => ({ data: [] }),
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        let socket!: ISocket

        try {
          await cacheDataLoaded

          if (!socket) {
            socket = SocketFactory.create()
          }

          socket.socket.on(`WS2CLIENT/GATEWAY_HEALTH/${arg}`, function (event) {
            updateCachedData((currentCacheData) => {
              currentCacheData.push(event)
            })
          })

          await cacheEntryRemoved
        } catch (error) {
          console.error("Failed to connect to Socket.io", error)
        }

        await cacheEntryRemoved
        socket.socket.off(`WS2CLIENT/GATEWAY_HEALTH/${arg}`)
      },
    }),
  }),
})

export const { useGetMessagesQuery } = api
