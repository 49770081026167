import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react"
import { getAccessToken } from "../../utils/getAccessToken"

export const thorBaseQuery = () =>
  fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_THOR_URL,
    prepareHeaders: (headers) => {
      const token = getAccessToken()

      if (token) headers.set("Authorization", `Bearer ${token}`)

      return headers
    },
  })
