import {
  TableView,
  TableContainer,
  TableHeader,
  TableColumn,
} from "./tableCss.styled"
import { RowsTableCssSkeleton } from "./components/Rows/Rows.skeleton"
interface TableConfigItem {
  label: string
}

interface TableProps<U> {
  configuration: U[]
  itens: number
}

export const TableCssSkeleton = <U extends TableConfigItem>(
  props: TableProps<U>
) => {
  const { configuration, itens } = props

  const renderTable = configuration.map((item) => {
    return (
      <div key={item.label} className='header-cell'>
        {item.label}
      </div>
    )
  })

  const renderRows = Array.from({ length: itens }, (_, idx) => (
    <RowsTableCssSkeleton config={configuration} key={idx} />
  ))

  return (
    <TableView>
      <TableContainer>
        <TableHeader>
          <div className='table-row'>
            <div className='checkbox-header header-cell' />
            {renderTable}
            <div className='expand-header header-cell' />
          </div>
        </TableHeader>
        <TableColumn>{renderRows}</TableColumn>
      </TableContainer>
    </TableView>
  )
}
