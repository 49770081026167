import { memo } from "react"
import { UseFormHandleSubmit } from "react-hook-form"
import { AssetSchema } from "../../../utils/schema"
import { Button } from "../../../../../../components/ui/Button"
import { useAppTranslate } from "../../../../../../translate/useAppTranslate"
import { ButtonsContainer } from "./styles"

interface ActionsProps {
  handleFormSubmit: UseFormHandleSubmit<AssetSchema>
  handleCancel: () => void
  handleCreate: (data: AssetSchema, parentId: string) => void
  isLoading: boolean
  assetId: string
}

export const Actions = memo(
  ({
    handleFormSubmit,
    handleCancel,
    handleCreate,
    isLoading,
    assetId,
  }: ActionsProps) => {
    const { buttons } = useAppTranslate()

    const onSubmit = (data: AssetSchema) => {
      const id = assetId.toString()
      handleCreate(data, id)
    }

    return (
      <>
        <ButtonsContainer>
          <Button variant='secondary' onClick={handleCancel}>
            {buttons.cancel}
          </Button>
          <Button onClick={handleFormSubmit(onSubmit)} disabled={isLoading}>
            {isLoading ? buttons.loading : buttons.save}
          </Button>
        </ButtonsContainer>
      </>
    )
  }
)

Actions.displayName = "Actions"
