import { SerializedError, createSlice } from "@reduxjs/toolkit"
import { Gateway } from "./gateways.interfaces"
import { fetchGateways } from "./gateways.api"

interface InitialState {
  isLoading: boolean
  data: Gateway[]
  error: SerializedError
}

const initialState: InitialState = {
  isLoading: false,
  data: [],
  error: {},
}

export const gatewaysSlice = createSlice({
  name: "gateways",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGateways.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchGateways.fulfilled, (state, action) => {
      state.isLoading = false
      state.data = action.payload
    })
    builder.addCase(fetchGateways.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.error
    })
  },
})

export default gatewaysSlice.reducer
