import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  min-height: 40px;
  min-width: 220px;
  padding: 5px;
  border: 1px solid #aeaeae;
  border-radius: 5px;
  position: relative;
`

export const AlgorithmButton = styled.button<{ $bgcolor: string }>`
  display: flex;
  flex-direction: row;
  border: none;
  border-radius: 4px;
  background: none;
  outline: none;
  width: 100%;
  min-height: 22px;

  justify-content: center;
  background: ${(props) => props.$bgcolor};
  color: white;
`
export const TSATextUnderAlgorithm = styled.div`
  font-weight: bold;
  font-size: small;
`
export const ExpandAlgorithmAccordion = styled.div`
  min-height: 230px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  position: relative;
`
export const AlgorithmDataContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const TextConfig = styled.span`
  font-size: small;
  margin-bottom: 5px;
  display: flex;
`
export const ReconfigAndResetBtn = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  bottom: 0px;
`
