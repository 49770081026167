import { MHeadar } from "../../styles"
import { IconButton } from "../../../ui/IconButton"
import { X } from "phosphor-react"

interface IModalHeader {
  onClose: () => void
  title: string
  subtitle?: string
}

export const ModalHeader = ({ onClose, title, subtitle }: IModalHeader) => {
  return (
    <MHeadar>
      <div>
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
      </div>
      <IconButton
        variant='secondary'
        icon={<X size={20} />}
        onClick={onClose}
      />
    </MHeadar>
  )
}
