import styled from "styled-components"
import { media } from "../../../../../../utils/media-queries"

export const IconButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media ${media.medium} {
    display: none;
  }
`

export const ButtonsContainer = styled.div`
  width: 100%;
  padding: 16px 16px 0px 16px;
  display: none;
  height: 100%;
  gap: 10px;
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: space-between;
`
