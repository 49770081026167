import { PropsWithChildren } from "react"
import { Column } from "../.."
import { Checkbox } from "../../../ui/Checkbox"
import { Text } from "../../../ui/Text"
import {
  Row,
  ContentRow,
  ContentCell,
  ExpandedRowCard,
  ExpandedCellCard,
} from "./styles"
import { Expand } from "../Expand"
import { Cell } from "../Cell"

interface CellRowProps<T> {
  columns: Column<T>[]
  row: T
  checked?: boolean
  expanded?: boolean
  check: (id: number | string) => void
  expand: (id: number | string) => void
  selectionType: "checkbox" | "radio"
}

export const CellRow = <T extends { id: number | string }>({
  columns,
  row,
  checked,
  check,
  children,
  expanded = false,
  expand,
  selectionType,
}: PropsWithChildren<CellRowProps<T>>) => {
  const hasExpandedContent = !!children
  const hasCheckbox = checked !== undefined

  const getTotalColumns = () => {
    return columns.length + (hasExpandedContent ? 1 : 0) + (hasCheckbox ? 1 : 0)
  }

  return (
    <>
      <Row $rowsize={columns.length} $checkbox={hasCheckbox}>
        {hasCheckbox && (
          <Cell>
            <Checkbox
              type={selectionType}
              checked={checked}
              onChange={() => check(row.id)}
              name='checkbox'
              aria-label={`${selectionType} selection`}
            />
          </Cell>
        )}
        {columns.map((column) => (
          <Cell key={column.label} data-label={column.label}>
            <Text color='gray.700' fontSize='sm' fontWeight='regular'>
              {column.render(row)}
            </Text>
          </Cell>
        ))}

        {hasExpandedContent && expanded && (
          <ContentCell>
            <ExpandedCellCard>{children}</ExpandedCellCard>
          </ContentCell>
        )}

        {hasExpandedContent && (
          <Cell onClick={() => expand(row.id)}>
            <Expand isExpanded={expanded} />
          </Cell>
        )}
      </Row>

      {hasExpandedContent && expanded && (
        <ContentRow $rowsize={1} $checkbox={hasCheckbox}>
          <ExpandedRowCard colSpan={getTotalColumns()}>
            {children}
          </ExpandedRowCard>
        </ContentRow>
      )}
    </>
  )
}
