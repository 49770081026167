import { CheckboxContainer, HiddenCheckbox, StyledCheckbox } from "./styles"
import { Check } from "phosphor-react"

type CheckTable = {
  isCheck: boolean
  onClick: () => void
}

export const CheckBoxUI = ({ isCheck, onClick }: CheckTable) => {
  return (
    <CheckboxContainer $isCheck={isCheck} onClick={onClick}>
      <HiddenCheckbox onClick={onClick} />
      <StyledCheckbox $isCheck={isCheck}>
        <Check weight='bold' size={16} />
      </StyledCheckbox>
    </CheckboxContainer>
  )
}
