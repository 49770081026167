import { SensorProv } from "../../../store/features/sensors/sensors.interfaces"
import { cleanAndPadSerialNumber } from "../../../utils/cleanAndPadSerialNumber"

export const filterAndOrderSensors = (
  sensors: SensorProv[],
  searchOption: number,
  searchValue: string,
  orderOption: number
) => {
  const filtered = filter(sensors, searchOption, searchValue)
  const ordered = orderDisplayedSensors(filtered, orderOption)

  return ordered
}

const filter = (
  allSensors: SensorProv[],
  serviceIndex: number,
  sensorInput: string
) => {
  const sensors: SensorProv[] = []

  const filteringBySensor = sensorInput !== ""

  for (let i = 0; i < allSensors.length; i++) {
    const sensor = allSensors[i]

    const hasResultName =
      !filteringBySensor || matchResult(sensorInput, sensor, serviceIndex)

    if (hasResultName) {
      sensors.push(sensor)
      continue
    }
  }

  return sensors
}

const matchResult = (input: string, sensor: SensorProv, option: number) => {
  switch (option) {
    case 1: {
      const exhibitionName = getExibitionName(sensor)
      return match(exhibitionName, input)
    }

    case 2: {
      return match(sensor.bleHdrMac, input)
    }

    case 3:
      return match(sensor.sensor.productionSerialNumber.toString(), input)

    default:
      return false
  }
}

const match = (text: string, find: string) => {
  return text.toLowerCase().includes(find.toLowerCase())
}

const getExibitionName = (sensor: SensorProv): string => {
  return sensor.name
    ? sensor.name
    : `S${cleanAndPadSerialNumber(sensor.sensor.productionSerialNumber, sensor.sensor.hardwareVersion)}`
}

const orderDisplayedSensors = (
  sensors: SensorProv[],
  orderOption: number
): SensorProv[] => {
  if (orderOption === 0) return sensors

  return sensors.sort((a, b) => {
    switch (orderOption) {
      case 1:
        if (!a.name && b.name) return 1
        if (a.name && !b.name) return -1
        if (!a.name || !b.name) return compare(a.bleHdrMac, b.bleHdrMac)
        return compare(a.name, b.name)

      case 2:
        return compare(a.bleHdrMac, b.bleHdrMac)

      case 3:
        return compare(
          a.sensor.productionSerialNumber,
          b.sensor.productionSerialNumber
        )

      case 4:
        if (!a.health && b.health) return 1
        if (a.health && !b.health) return -1
        if (!a.health || !b.health) return compare(a.bleHdrMac, b.bleHdrMac)
        return compare(a.health.temp, b.health.temp)

      case 5:
        if (!a.health && b.health) return 1
        if (a.health && !b.health) return -1
        if (!a.health || !b.health) return compare(a.bleHdrMac, b.bleHdrMac)
        return compare(a.health.voltage, b.health.voltage)

      case 6:
        if (!a.health && b.health) return 1
        if (a.health && !b.health) return -1
        if (!a.health || !b.health) return compare(a.bleHdrMac, b.bleHdrMac)
        return compare(a.health.rssi, b.health.rssi)

      case 7:
        if (!a.health && b.health) return 1
        if (a.health && !b.health) return -1
        if (!a.health || !b.health) return compare(a.bleHdrMac, b.bleHdrMac)
        return compareDates(a.health.time, b.health.time)

      case 8:
        if (!a.health && b.health) return 1
        if (a.health && !b.health) return -1
        if (!a.health || !b.health) return compare(a.bleHdrMac, b.bleHdrMac)
        return compareDates(a.health.lastReset, b.health.lastReset)

      default:
        return -1
    }
  })
}

const compare = (a: number | string, b: number | string) => {
  if (!a && b) return 1
  if (a && !b) return -1

  if (!a || !b) return 0

  return a <= b ? -1 : 1
}

const compareDates = (a: string, b: string) => {
  if (!a && b) return 1
  if (a && !b) return -1
  if (!a || !b) return 0

  const firstDate = new Date(a)
  const secondDate = new Date(b)

  return firstDate <= secondDate ? -1 : 1
}
