import { FC } from "react"
import * as S from "./styles"
import { useTableSensorsController } from "./controller/useTableSensorsController"
import { Text } from "../../../../../ui/Text"
import { FlexContainer } from "../../../../../ui/FlexContainer"
import { Checkbox } from "../../../../../ui/Checkbox"
import { useAppTranslate } from "../../../../../../translate/useAppTranslate"

export const TableSensors: FC = () => {
  const {
    dashboard: { configureSensors },
  } = useAppTranslate()

  const { sensors, getCheckboxState, handleChangeCheckbox, getNameSensor } =
    useTableSensorsController()

  const hasServices = sensors.some((sensor) => sensor.services.length > 0)

  if (!hasServices)
    return (
      <FlexContainer align='center'>
        <Text>{configureSensors.empty}</Text>
      </FlexContainer>
    )

  return (
    <S.Table>
      <S.TableHeader>
        <tr>
          <th>
            <FlexContainer align='start'>
              <Text fontWeight='medium' color='gray.800'>
                {configureSensors.sensor}
              </Text>
            </FlexContainer>
          </th>
          <th colSpan={50}>
            <FlexContainer align='start' padding='0 0 0 10px'>
              <Text fontWeight='medium' color='gray.800'>
                {configureSensors.services}
              </Text>
            </FlexContainer>
          </th>
        </tr>
      </S.TableHeader>

      <S.TableBody>
        {sensors.map(
          (sensor) =>
            sensor.services.length > 0 && (
              <S.Row key={sensor.mac}>
                <td>
                  <FlexContainer align='start'>
                    <Text fontWeight='medium' color='gray.800'>
                      {getNameSensor(sensor.name, sensor.serialNumber)}
                    </Text>
                  </FlexContainer>
                </td>
                {sensor.services.map((service) => {
                  if (!service.axis) {
                    return (
                      <S.ContainerService key={service.name}>
                        <S.Label>
                          <Checkbox
                            type='checkbox'
                            value={service.name}
                            checked={getCheckboxState(
                              sensor.mac,
                              service.name,
                              undefined
                            )}
                            onChange={() =>
                              handleChangeCheckbox(
                                sensor.mac,
                                service.name,
                                undefined
                              )
                            }
                          />
                          <Text color='gray.700'>
                            {service.name.toUpperCase()}
                          </Text>
                        </S.Label>
                      </S.ContainerService>
                    )
                  }

                  return service.axis.map((ax) => (
                    <S.ContainerService key={service.name + ax}>
                      <S.Label>
                        <Checkbox
                          type='checkbox'
                          value={service.name}
                          checked={getCheckboxState(
                            sensor.mac,
                            service.name,
                            ax
                          )}
                          onChange={() =>
                            handleChangeCheckbox(sensor.mac, service.name, ax)
                          }
                        />
                        <Text color='gray.700'>{`${service.name.toUpperCase()}/${ax.toUpperCase()}`}</Text>
                      </S.Label>
                    </S.ContainerService>
                  ))
                })}
              </S.Row>
            )
        )}
      </S.TableBody>
    </S.Table>
  )
}
