import { Skeleton } from "@mui/material"
import { WrapperText, Container } from "./styles"

export const TitleAndTextSkeleton = () => (
  <Container>
    <Skeleton variant='text' width={100} />
    <WrapperText>
      <Skeleton variant='text' />
      <Skeleton variant='text' />
    </WrapperText>
  </Container>
)
