import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md"
import { ConfigButton } from "../../../../components/Buttons/ConfigButton"
import { useAlgorithmCardController } from "../../controllers/useAlgorithmCardController"
import { AlgorithmsFormattedDTO } from "../../entities/AlgorithmsFormattedDTO"
import * as S from "./styles"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { ExtraInformationInLocalStorage } from "../ExtraInformationPOT"

interface AlgorithmCardProps {
  algorithm: AlgorithmsFormattedDTO
  serviceName:
    | "temp"
    | "rms2"
    | "rmms"
    | "tilt"
    | "fft"
    | "accRaw"
    | "_4t20"
    | "ntc"
    | "pot"
  bleHdrMac: string
}

export const AlgorithmCard = ({
  algorithm,
  serviceName,
  bleHdrMac,
}: AlgorithmCardProps) => {
  const {
    algorithmAccordion,
    RIGHT_POSITION_OF_SENSORS_SERVICES_CONFIGS,
    TRANSLATE_HDR_SERVICE_TYPE_INTO_PLAIN_PORTUGUESE,
    handleAlgorithAccordionClick,
    transformHowTextWillBePresented,
    handleOpenModal,
    handleOpenModalDesableOrReset,
    searchLastPOTData,
    referencePOT,
    resetReferencePOT,
    getLastDataPOTSalved,
  } = useAlgorithmCardController(bleHdrMac)

  const { [serviceName]: serviceData } = algorithm
  const isConfigured = serviceData !== null

  const { sensorsConfig, buttons } = useAppTranslate()

  const GET_TEXT_FROM_CONFIGS = {
    axis: sensorsConfig.axle,
    sens: sensorsConfig.sensitivity,
    res: sensorsConfig.resolution,
    freq: sensorsConfig.frequency,
    nsCalc: sensorsConfig.sample_number,
    nse: sensorsConfig.shipping_acquisitions,
    freqLow: sensorsConfig.freqLow,
    freqHigh: sensorsConfig.freqHigh,
    channel: sensorsConfig.channel,
    tsa: sensorsConfig.time_between_acquisitions,
  }

  const AlgorithmArrowButton = ({
    isConfigured,
    isOpen,
  }: {
    isConfigured: boolean
    isOpen: boolean
  }) => (
    <>
      {isConfigured && algorithmAccordion.length > 0 ? (
        isOpen ? (
          <MdKeyboardArrowUp size={22} />
        ) : (
          <MdKeyboardArrowDown size={22} />
        )
      ) : null}
    </>
  )

  return (
    <S.Container>
      <S.AlgorithmButton
        $bgcolor={isConfigured ? "#2F90D6" : "grey"}
        onClick={(e) =>
          handleAlgorithAccordionClick(e, serviceName, isConfigured)
        }
      >
        <h3>{TRANSLATE_HDR_SERVICE_TYPE_INTO_PLAIN_PORTUGUESE[serviceName]}</h3>
        <AlgorithmArrowButton
          isConfigured={isConfigured}
          isOpen={algorithmAccordion.includes(serviceName)}
        />
      </S.AlgorithmButton>

      <S.TSATextUnderAlgorithm>
        {isConfigured &&
          transformHowTextWillBePresented(
            "tsa",
            algorithm[serviceName]?.tsa,
            serviceName
          )}
      </S.TSATextUnderAlgorithm>
      {isConfigured &&
        algorithmAccordion.length > 0 &&
        algorithmAccordion.includes(serviceName) && (
          <S.ExpandAlgorithmAccordion>
            <S.AlgorithmDataContainer>
              {isConfigured &&
                RIGHT_POSITION_OF_SENSORS_SERVICES_CONFIGS[serviceName].map(
                  (config, index: number) => (
                    <S.TextConfig key={index}>
                      <strong>{GET_TEXT_FROM_CONFIGS[config]}</strong>
                      {`: ${transformHowTextWillBePresented(
                        config,
                        serviceData?.[config],
                        serviceName
                      )?.toString()}`}
                    </S.TextConfig>
                  )
                )}
              {isConfigured &&
                (serviceName === "pot" || serviceName === "_4t20") && (
                  <ExtraInformationInLocalStorage
                    setReferencePOT={searchLastPOTData}
                    referencePOT={referencePOT}
                    resetReferencePOT={resetReferencePOT}
                    mac={bleHdrMac}
                    serviceName={serviceName}
                    getLastDataPOTSalved={getLastDataPOTSalved}
                  />
                )}
            </S.AlgorithmDataContainer>

            <S.ReconfigAndResetBtn>
              <ConfigButton
                type='reconfigure'
                text={buttons.reconfigure}
                _onClick={(e) => handleOpenModal(serviceName, serviceData)}
              />
              <ConfigButton
                type='disable'
                text={buttons.disable}
                _onClick={(e) =>
                  handleOpenModalDesableOrReset("disable", serviceName)
                }
              />
            </S.ReconfigAndResetBtn>
          </S.ExpandAlgorithmAccordion>
        )}
    </S.Container>
  )
}
