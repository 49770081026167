import {
  useUpdateAssetMutation,
  useDeleteAssetMutation,
} from "../../../../../store/store"
import { AssetSchema } from "../../utils/schema"
import { toast } from "react-toastify"
import { useMemo, useState } from "react"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"
import { useNavigate } from "react-router-dom"

export const useConfigureAsset = ({ id }: { id: number }) => {
  const navigate = useNavigate()

  const { assets } = useAppTranslate()

  const [isModalConfigureAssetOpen, setIsModalConfigureAssetOpen] =
    useState(false)
  const handleOpenConfigureAssetModal = () => {
    setIsModalConfigureAssetOpen(true)
  }

  const handleCloseConfigureAssetModal = () => {
    setIsModalConfigureAssetOpen(false)
  }

  const [updateAsset, mutation] = useUpdateAssetMutation()
  const [deleteAsset, mutationDelete] = useDeleteAssetMutation()

  const isDisable = useMemo(
    () => mutation.isLoading || mutationDelete.isLoading,
    [mutation.isLoading, mutationDelete.isLoading]
  )

  const handleCancel = () => {
    handleCloseConfigureAssetModal()
  }

  const handleSubmit = async (data: AssetSchema) => {
    if (!id) return

    try {
      await updateAsset({
        id: Number(id),
        data: {
          name: data.name,
          description: data.description,
        },
      })

      handleCloseConfigureAssetModal()

      toast.success(assets.form.toastSucessConfigure)
    } catch (e) {
      console.log(e)
      toast.error("Erro ao atualizar o asset. Tente novamente.")
    }
  }

  const handleDelete = () => {
    deleteAsset(id)
      .unwrap()
      .then(() => {
        navigate(`/assets`)
        toast.success(assets.form.toastSucessDelete)
        handleCloseConfigureAssetModal()
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return {
    handleCancel,
    handleDelete,
    handleSubmit,
    handleOpenConfigureAssetModal,
    handleCloseConfigureAssetModal,
    isDisable,
    isModalConfigureAssetOpen,
  }
}
