import { Middleware } from "redux"
import SocketFactory from "../../services/SocketFactory"
import { ISocket } from "../../services/SocketFactory"
import { connectionEstablished, connectionLost } from "./socketSlice"

enum SocketEvent {
  Connect = "connect",
  Disconnect = "disconnect",
  ReceiveMessage = "receive_message",
  Error = "err",
}

const socketMiddleware: Middleware = (store) => {
  let socket: ISocket

  return (next) => (action) => {
    if (!socket && typeof window !== "undefined") {
      socket = SocketFactory.create()

      socket.socket.on(SocketEvent.Connect, () => {
        store.dispatch(connectionEstablished())
      })

      socket.socket.on(SocketEvent.Error, (message) => {
        console.error(message)
      })

      socket.socket.on(SocketEvent.Disconnect, (reason) => {
        store.dispatch(connectionLost())
      })
    }

    next(action)
  }
}

export default socketMiddleware
