import { MapPinSimpleArea, Engine, Folder } from "@phosphor-icons/react"
import * as S from "./styles"
import { AssetType } from "../../../../../../../../utils/entities/assets"

const getIconByType = (type: AssetType) => {
  switch (type) {
    case AssetType.ORGANIZATION:
      return MapPinSimpleArea
    case AssetType.ADL:
      return Folder
    case AssetType.LOCAL:
      return MapPinSimpleArea
    case AssetType.MOTOR:
      return Engine
  }
}

export const AssetIcon = ({ type }: { type: AssetType }) => {
  const Icon = getIconByType(type)

  return (
    <S.AssetIcon>
      <Icon size={20} />
    </S.AssetIcon>
  )
}
