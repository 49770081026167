import styled from "styled-components"

export type Justify =
  | "center"
  | "start"
  | "end"
  | "space-between"
  | "space-around"

export type Align = "center" | "start" | "end" | "stretch"

export const Container = styled.div<{
  $direction: "row" | "column"
  $align: Align
  $justify: Justify
  $gap: number
  $fullWidth?: boolean
  $fullHeigth?: boolean
}>`
  display: flex;
  flex-direction: ${({ $direction }) => $direction};
  align-items: ${({ $align }) => $align};
  justify-content: ${({ $justify }) => $justify};
  gap: ${({ $gap }) => $gap}px;
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "auto")};
  height: ${({ $fullHeigth }) => ($fullHeigth ? "100%" : "auto")};
`
