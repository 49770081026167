import { memo, useState } from "react"
import { UseFormHandleSubmit } from "react-hook-form"
import { AssetSchema } from "../../../utils/schema"
import { Button } from "../../../../../../components/ui/Button"
import { ModalDeleteAsset } from "../ModalDeleteAsset"
import { useAppTranslate } from "../../../../../../translate/useAppTranslate"
import { ButtonsContainer } from "./styles"

interface ActionsProps {
  id: number
  isRoot: boolean
  handleFormSubmit: UseFormHandleSubmit<AssetSchema>
  handleDelete: () => void
  handleSubmit: (data: AssetSchema) => Promise<void>
  isDisable: boolean
}

export const Actions = memo(
  ({
    id,
    isRoot,
    handleFormSubmit,
    handleDelete,
    handleSubmit,
    isDisable,
  }: ActionsProps) => {
    const { assets } = useAppTranslate()

    const [open, setOpen] = useState(false)
    return (
      <>
        {open && (
          <ModalDeleteAsset
            id={id}
            handleOnClose={() => setOpen(false)}
            handleDelete={handleDelete}
            isDisable={isDisable}
          />
        )}

        <ButtonsContainer $isSingleButton={isRoot}>
          {!isRoot && (
            <Button
              variant='secondary'
              onClick={() => setOpen(true)}
              disabled={isDisable}
            >
              {assets.form.deleteAsset}
            </Button>
          )}

          <Button
            variant='primary'
            onClick={handleFormSubmit(handleSubmit)}
            disabled={isDisable}
          >
            {assets.form.saveChanges}
          </Button>
        </ButtonsContainer>
      </>
    )
  }
)

Actions.displayName = "Actions"
