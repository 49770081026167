import React, { ComponentPropsWithoutRef, ElementType } from "react"
import * as S from "./styles"
import { CardHeader } from "./components/CardHeader"
import { CardIcon } from "./components/CardIcon"
import { Skeleton } from "@mui/material"

type CardProps<T extends ElementType> = {
  title: string
  icon?: JSX.Element
  date?: Date
  children?: React.ReactNode
  tag?: string
  status?: "read" | "unread"
  type?: "notification" | "message"
  skeleton?: boolean
} & ComponentPropsWithoutRef<T>

export const Card = <C extends ElementType>({
  as: Component = S.CardWrapper,
  title,
  children,
  date,
  icon,
  tag,
  status = "read",
  type = "message",
  skeleton = false,
  ...props
}: CardProps<C>) => {
  return (
    <Component {...props}>
      <CardIcon status={status} icon={icon} type={type} skeleton={skeleton} />
      <S.Wrapper>
        <CardHeader
          title={title}
          icon={icon}
          date={date}
          status={status}
          subTitle={tag}
          type={type}
          skeleton={skeleton}
        />
        {skeleton ? (
          <>
            <Skeleton variant='text' width='100%' />
            <Skeleton variant='text' width='100%' />
            <Skeleton variant='text' width='100%' />
          </>
        ) : (
          <S.CardContent>{children}</S.CardContent>
        )}
      </S.Wrapper>
    </Component>
  )
}
