import styled from "styled-components"

export const TreeAssetsContainer = styled.div`
  min-width: 274px;
  overflow: auto;
  flex: 1 1 auto;

  border-top: 1px solid ${(props) => props.theme.palette.gray[300]};
  margin-top: 12px;
`

export const TreeAssetsSkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
`
