/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState } from "react"

export const useTableCss = <T extends Record<string, any>>() => {
  const [itemsSelected, setItemsSelected] = useState<T[]>([])
  const [expandedRow, setExpandedRow] = useState(false)
  const [resetCheckboxes, setResetCheckboxes] = useState(false)
  const [checked, setChecked] = useState(false)

  const updateSelectedValues = useCallback((item: T, check: boolean) => {
    setItemsSelected((prevSelected) => {
      if (check) {
        return [...prevSelected, item]
      } else {
        return prevSelected.filter(
          (selectedItem) => selectedItem.id !== item.id
        )
      }
    })
  }, [])

  const uncheckAll = () => {
    setItemsSelected([])
    setResetCheckboxes((prev) => !prev)
  }

  const showRow = () => {
    setExpandedRow(!expandedRow)
  }

  return {
    itemsSelected,
    updateSelectedValues,
    uncheckAll,
    expandedRow,
    setExpandedRow,
    showRow,
    resetCheckboxes,
    checked,
    setChecked,
  }
}
