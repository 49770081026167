import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"

import ptBR from "./languages/pt.json"
import enUS from "./languages/en.json"
import esLA from "./languages/spa.json"

import errorPt from "./languages/errors/errorPt.json"
import errorEn from "./languages/errors/errorEn.json"
import errorEs from "./languages/errors/errorEs.json"

import { initReactI18next } from "react-i18next"

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    defaultNS: ["translation"],
    fallbackLng: "pt-BR",
    interpolation: {
      escapeValue: false,
    },
    ns: ["translation"],
    resources: {
      "pt-BR": { translation: { ...ptBR, ...errorPt } },
      "en-US": { translation: { ...enUS, ...errorEn } },
      "es-LA": { translation: { ...esLA, ...errorEs } },
    },
  })

export default i18n
