import { ComponentProps } from "react"
import { FlexContainer } from "../../../../../../components/ui/FlexContainer"
import { Checkbox } from "../../../../../../components/ui/Checkbox"
import { Text } from "../../../../../../components/ui/Text"

interface InputChartTypeProps extends Omit<ComponentProps<"input">, "type"> {
  title: string
  subtitle: string
}

export const InputChartType = ({
  title,
  subtitle,
  ...props
}: InputChartTypeProps) => {
  return (
    <FlexContainer direction='row' align='start' gap={8}>
      <Checkbox type='radio' {...props} />

      <FlexContainer align='start'>
        <Text fontWeight='medium' color='gray.700'>
          {title}
        </Text>
        <Text color='gray.500'>{subtitle}</Text>
      </FlexContainer>
    </FlexContainer>
  )
}
