import { Dispatch, SetStateAction, createContext, useState } from "react"
import { Container } from "./styles"

type TabsContextProps = {
  selectedIndex: number
  setSelectedIndex: Dispatch<SetStateAction<number>>
}

export const TabsContext = createContext<TabsContextProps | null>(null)

export const Tabs = ({ children, ...props }: React.ComponentProps<"div">) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  return (
    <TabsContext.Provider value={{ selectedIndex, setSelectedIndex }}>
      <Container>{children}</Container>
    </TabsContext.Provider>
  )
}
