import {
  Container,
  Root,
  Header,
  Body,
  HeaderRow,
  HeaderCell,
} from "./table.styled"
import { CellRow } from "./components/CellRow"
import { Text } from "../ui/Text"
import { Footer } from "../Table/components/Footer"

export interface Column<T> {
  label: string
  render: (param: T) => string | number
  order?: {
    by: keyof T
  }
}

export interface TableProps<T> {
  columns: Column<T>[]
  data: number
  total: number
  itemsPerPage: number
  page: number
  onChangeItemsPerPage: (items: number) => void
  onChangePage: (page: number) => void
  checkbox?: boolean
}

export const TableSkeleton = <
  T extends {
    id: number | string
  },
>({
  columns,
  checkbox = false,
  data,
  total,
  itemsPerPage,
  page,
  onChangeItemsPerPage,
  onChangePage,
}: TableProps<T>) => {
  return (
    <Container>
      <Root $checkbox={checkbox} $expand={false}>
        <Header>
          <HeaderRow $rowsize={columns.length}>
            {checkbox && <HeaderCell />}

            {columns.map((column, index) => {
              return (
                <HeaderCell key={index}>
                  <Text color='gray.800' fontSize='sm' fontWeight='medium'>
                    {column.label}
                  </Text>
                </HeaderCell>
              )
            })}
          </HeaderRow>
        </Header>

        <Body>
          {Array.from({ length: data }, (_, index) => index + 1).map(
            (row, index) => {
              return (
                <CellRow
                  key={index}
                  checked={checkbox}
                  columns={columns as Column<{ id: number }>[]}
                  row={row}
                />
              )
            }
          )}
        </Body>
      </Root>

      <Footer
        total={total}
        onChangeData={() => {}}
        itemsPerPage={itemsPerPage}
        page={page}
        onChangeItemsPerPage={onChangeItemsPerPage}
        onChangePage={onChangePage}
      />
    </Container>
  )
}
