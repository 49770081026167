import { forwardRef, InputHTMLAttributes } from "react"
import { Container, Input } from "./styles"
import { Text } from "../Text"

interface DatetimeInputProps extends InputHTMLAttributes<HTMLInputElement> {
  isEmpty: boolean
  error?: string
  label?: string
}

export const DatetimeInput = forwardRef<HTMLInputElement, DatetimeInputProps>(
  ({ isEmpty, error, label, ...props }, ref) => {
    return (
      <Container>
        {label && (
          <Text color='gray.600' fontSize='xs' fontWeight='semibold'>
            {label}
          </Text>
        )}
        <Input $empty={isEmpty} ref={ref} {...props} />
        {error && (
          <Text color='error.main' fontSize='xs'>
            {error}
          </Text>
        )}
      </Container>
    )
  }
)

DatetimeInput.displayName = "DatetimeInput"
