import { Skeleton } from "@mui/material"
import { Container } from "../styles"

export const AssetTypeSkeleton = () => {
  return (
    <Container>
      <Skeleton variant='text' width={"25%"} height={30} />
      <Skeleton variant='text' width={"25%"} height={30} />
    </Container>
  )
}
