import { Title } from "../../../../../components/Title"
import {
  Actions,
  ConfigButton,
  Container,
  Details,
  Header,
  IconButton,
  Services,
} from "./styles"
import { IconService } from "../../../../../components/IconService"
import { Gear, Trash } from "phosphor-react"
import { SensorService } from "../../../../../utils/entities/assets"
import { useDeleteLinkSensorMutation } from "../../../../../store/store"
import { ModalHedro } from "../../../../../components/ModalHedro"
import { useState } from "react"
import { Text } from "../../../../../components/ui/Text"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"
import { ModalConfigureSensor } from "../ModalConfigureSensor"
import { formatedDate } from "../../../../../utils/date"

interface AssetWithSensorProps {
  servicesConfigured?: SensorService[]
  associatedAt?: number
  sensorName: string
  assetId: number
  assetName: string
}

export const AssetWithSensor = ({
  servicesConfigured,
  associatedAt,
  sensorName,
  assetId,
  assetName,
}: AssetWithSensorProps) => {
  const { assets } = useAppTranslate()

  const [removeSensor, mutation] = useDeleteLinkSensorMutation()

  const [open, setOpen] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      {open && (
        <ModalHedro
          onClose={() => setOpen(false)}
          onAction={() =>
            removeSensor({
              id: assetId,
              sensorId: 1,
            })
          }
          actionBar={assets.sensors.deleteSensor}
          title={assets.sensors.deleteSensor}
          disableAction={mutation.isLoading}
        >
          <Text fontSize='sm' fontWeight='regular' color='gray.700'>
            {`${assets.sensors.deleteModalMessage.part1} ${sensorName} ${assets.sensors.deleteModalMessage.part2} "${assetName}".`}
          </Text>
        </ModalHedro>
      )}
      {isModalOpen && (
        <ModalConfigureSensor
          handleCloseModal={handleCloseModal}
          assetId={assetId}
          sensorId={sensorName}
          associatedAt={associatedAt}
        />
      )}
      <Header>
        <Title label='Sensor' size='sm' weight='medium' />
        <Actions>
          <ConfigButton onClick={handleOpenModal}>
            <Gear size={22} weight='bold' />
          </ConfigButton>
          <IconButton onClick={() => setOpen(true)}>
            <Trash size={22} weight='bold' />
          </IconButton>
        </Actions>
      </Header>
      <Details>
        <Container>
          <Title label={sensorName} size='md' weight='medium' />
          <Services>
            {servicesConfigured?.map((element, index) => (
              <IconService
                key={index}
                serviceType={element.name}
                isConfigured={element.isConfigured}
              />
            ))}
          </Services>
        </Container>
        {associatedAt && (
          <Text>
            {assets.form.sensors.associatedAt}: {formatedDate(associatedAt)}
          </Text>
        )}
      </Details>
    </>
  )
}
