import styled from "styled-components"
import { media } from "../../../../../../utils/media-queries"

export const Container = styled.div`
  display: flex;
  justify-content: start;

  box-sizing: border-box;

  width: 100%;

  @media ${media.medium} {
    height: calc(85vh - 28px + 1px);
    margin: 0 12px;
    width: auto;
  }
`

export const AssetsContainer = styled.div<{ open: boolean }>`
  background-color: ${(props) => props.theme.palette.gray[50]};

  overflow: hidden;

  border: 1px solid ${(props) => props.theme.palette.gray[400]};
  border-radius: 4px;

  box-sizing: content-box;

  display: flex;
  flex-flow: column;

  width: 100%;

  @media ${media.medium} {
    width: 274px;

    @keyframes showTree {
      0% {
        max-width: 0;
      }
      100% {
        max-width: 274px;
      }
    }

    @keyframes hideTree {
      0% {
        opacity: 1;
        max-width: 274px;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 0;
        display: none;
        max-width: 0;
      }
    }

    animation: ${(props) =>
      props.open
        ? "showTree 750ms ease-out forwards"
        : "hideTree 750ms ease-out forwards"};
  }
`

export const AssetsHeader = styled.div`
  display: none;

  border-bottom: 1px solid ${(props) => props.theme.palette.gray[400]};
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;

  padding-right: 10px;

  height: 44px;

  background-color: ${(props) => props.theme.palette.gray[100]};

  @media ${media.medium} {
    display: flex;
    justify-content: end;
    align-items: center;
  }
`

export const IconContainer = styled.div<{ open: boolean }>`
  width: 44px;

  display: none;

  @media ${media.medium} {
    display: ${({ open }) => (open ? "none" : "block")};

    @keyframes showIcon {
      0% {
        opacity: 0;
        width: 0;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
        width: 44px;
      }
    }

    animation: ${({ open }) => !open && "showIcon 1s forwards"};
  }
`
