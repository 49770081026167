import React from "react"
import { Card } from "../../../../../../../components/Card"
import { Divider } from "../../../../../../../components/ui/Divider"
import { HistoryHeader } from "../../HistoryCard/components/HistoryHeader"
import { Container } from "./styles"

export const HistoryCardSkeleton = () => {
  const skeletonItems = 8

  return (
    <Container>
      <HistoryHeader />
      {Array.from({ length: skeletonItems }, (_, index) => (
        <React.Fragment key={index}>
          <Card title='' type='notification' skeleton />
          <Divider />
        </React.Fragment>
      ))}
    </Container>
  )
}
