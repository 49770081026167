import { Sensor } from "../../../../../utils/entities/assets"
import { Column, Table } from "../../../../../components/Table"
import { MessageError } from "./styles"
import { useFetchSensorsQuery } from "../../../../../store/store"
import { useMemo, useState } from "react"
import { TableSkeleton } from "../../../../../components/TableSkeleton"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"

interface SensorRow {
  id: string
  name: string
  serialNumber: string
}

export const AssetSensorsTable = ({
  onCheck,
  defaultValue,
}: {
  onCheck: (id: string) => void
  defaultValue?: string
}) => {
  const { assets } = useAppTranslate()

  const [limit, setLimit] = useState(5)
  const [offset, setOffset] = useState(1)

  const { data, isFetching, isLoading, isError } = useFetchSensorsQuery({
    limit,
    offset,
  })

  const rows = useMemo(() => {
    if (!data) return []

    return data.data.map((sensor: Sensor) => ({
      ...sensor,
      id: sensor.serialNumber,
    }))
  }, [data])

  const columns: Column<SensorRow>[] = [
    {
      label: assets.sensors.name,
      render: ({ name }) => name,
    },
    {
      label: assets.sensors.serialNumber,
      render: ({ serialNumber }) => serialNumber,
    },
  ]

  if (isFetching || isLoading)
    return (
      <TableSkeleton
        columns={columns}
        data={limit}
        total={0}
        itemsPerPage={limit}
        page={offset}
        onChangeItemsPerPage={(items) => setLimit(items)}
        onChangePage={(page) => setOffset(page)}
        checkbox={true}
      />
    )

  if (isError || !data)
    return <MessageError>Erro ao buscar sensores</MessageError>

  return (
    <Table
      emptyMessage='Nenhum sensor identificado!'
      columns={columns}
      data={rows}
      total={data.count}
      itemsPerPage={limit}
      page={offset}
      onChangeItemsPerPage={(items) => setLimit(items)}
      onChangePage={(page) => setOffset(page)}
      checkbox
      onChangeData={(a, b) => {}}
      selectionType='radio'
      onCheck={(id) => onCheck(id.toString())}
      defaultValue={defaultValue}
    />
  )
}
