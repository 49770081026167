/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  TableView,
  TableContainer,
  TableHeader,
  TableColumn,
  TableFooter,
} from "./tableCss.styled"
import { RowsTableCss } from "./components/Rows"
import { BoxActionTable } from "./components/BoxAction"
import { useWebSocket } from "../../../hooks/useWebSocket"
import { useTableCss } from "./hooks/useTableCss"

interface TableProps<T, U, W> {
  items: T[]
  configuration: U[]
  renderExpand: W[]
  functions?: TableButton<T>[]
}

export interface TableButton<T> {
  label: string
  onClick: (items: T[]) => void
  variant?: "primary" | "secondary" | "destructive"
  type: "single" | "mult"
}

export const TableCss = <
  T extends Record<string, any>,
  U extends Record<string, any>,
  W extends Record<string, any>,
>(
  props: TableProps<T, U, W>
) => {
  const { items, configuration, renderExpand, functions } = props
  const { messages } = useWebSocket()

  const { itemsSelected, updateSelectedValues, uncheckAll, resetCheckboxes } =
    useTableCss<T>()

  const renderTable = configuration.map((item) => {
    return (
      <div key={item.label} className='header-cell'>
        {item.label}
      </div>
    )
  })

  const renderRows = items.map((item) => {
    const dataSocket = messages(item.ble_hdr_mac)
    const lastGatewayData = dataSocket?.slice(-1)
    const gateway = lastGatewayData ? lastGatewayData[0] : {}
    const combined = {
      ...item,
      value: gateway,
    }
    return (
      <RowsTableCss
        row={combined}
        config={configuration}
        onChange={updateSelectedValues}
        renderExpandContent={renderExpand}
        key={item.id}
        resetCheckboxes={resetCheckboxes}
      />
    )
  })

  return (
    <TableView>
      <TableContainer>
        <TableHeader>
          <div className='table-row'>
            <div className='checkbox-header header-cell' />
            {renderTable}
            <div className='expand-header header-cell' />
          </div>
        </TableHeader>
        <TableColumn>{renderRows}</TableColumn>
      </TableContainer>
      <TableFooter>
        <BoxActionTable
          functions={functions}
          count={itemsSelected.length}
          items={itemsSelected}
          uncheckAll={uncheckAll}
        />
      </TableFooter>
    </TableView>
  )
}
