import { useNavigate } from "react-router-dom"
import { useCreateAssetMutation } from "../../../../../store/store"

import { useMemo, useState } from "react"
import { AssetSchema } from "../../utils/schema"
import { toast } from "react-toastify"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"

export const useCreateAsset = () => {
  const { assets } = useAppTranslate()
  const navigate = useNavigate()

  const [openModalCreate, setOpenModalCreate] = useState(false)

  const [createAsset, mutation] = useCreateAssetMutation()

  const isLoading = useMemo(() => mutation.isLoading, [mutation.isLoading])

  const handleOpenCreateModal = () => {
    setOpenModalCreate(true)
  }

  const handleCloseCreateModal = () => {
    setOpenModalCreate(false)
  }

  const handleCreate = async (data: AssetSchema, parentId: string) => {
    if (!parentId) return

    try {
      const asset = await createAsset({
        parentId: Number(parentId),
        data,
      }).unwrap()

      handleCloseCreateModal()

      navigate(`/assets/${asset.id}`)

      toast.success(assets.form.toastSucessCreate)
    } catch (error) {
      toast.error(assets.form.toastErrorCreate)
      console.error(error)
    }
  }

  return {
    handleCreate,
    handleOpenCreateModal,
    handleCloseCreateModal,
    isLoading,
    openModalCreate,
  }
}
