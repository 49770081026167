import { useState } from "react"
import {
  useFetchGatewaysQuery,
  useUpdateGatewayNameMutation,
} from "../../../../store/api/GatewaysApi"
import { toast } from "react-toastify"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { IGatewayDataTable } from "../../../../store/features/gateways/gateways.interfaces"

export const useGatewayController = () => {
  const { gateways } = useAppTranslate()

  const [isOpenModalRename, setIsOpenModalRename] = useState<boolean>(false)
  const [selectedItems, setSelectedItems] = useState<IGatewayDataTable[]>([])
  const [gatewayName, setGatewayName] = useState<string>("")

  const {
    data: listGateways = [],
    isFetching,
    refetch,
    isError,
  } = useFetchGatewaysQuery()

  const [updateGatewayName, { isLoading }] = useUpdateGatewayNameMutation()

  const handleOpenModal = () => {
    setIsOpenModalRename(true)
  }

  const handleCloseModal = () => {
    setIsOpenModalRename(false)
    setGatewayName("")
  }

  const handleEditNameGateway = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setGatewayName(event.target.value)
  }

  const renameGateway = async () => {
    if (!gatewayName || gatewayName.trim() === "") {
      toast.info(gateways.gatewayNameCannotBeEmpty)
      return
    }

    if (selectedItems.length > 0) {
      const gatewayId = selectedItems[0].id
      try {
        await updateGatewayName({ id: gatewayId, name: gatewayName }).unwrap()
        handleCloseModal()
        toast.success(gateways.gatewayRenamedSuccessfully)
      } catch (error) {
        toast.error("Erro ao renomear gateway.")
      }
    }
  }

  return {
    isOpenModalRename,
    handleCloseModal,
    handleOpenModal,
    renameGateway,
    setSelectedItems,
    handleEditNameGateway,
    listGateways,
    isError,
    isFetching,
    refetch,
    isLoading,
  }
}
