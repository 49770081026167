import { Siren, Trash } from "@phosphor-icons/react"
import { Card } from "../../../../../../../../components/Card"
import {
  AssetHistoryData,
  AssetHistoryDataType,
} from "../../../../../../../../utils/entities/assets"
import { Divider } from "../../../../../../../../components/ui/Divider"
import React from "react"
import { useAppTranslate } from "../../../../../../../../translate/useAppTranslate"

interface HistoryContentProps {
  historyData: AssetHistoryData[]
}

const useHistoryType = () => {
  const { assets } = useAppTranslate()

  const getHistoryType = (type: AssetHistoryDataType) => {
    switch (type) {
      case AssetHistoryDataType.CONFIGURATION_CHANGED:
        return {
          icon: <Siren />,
          title: assets.history.configurationChanged,
        }
      case AssetHistoryDataType.SENSOR_REMOVED:
        return {
          icon: <Trash />,
          title: assets.history.sensorRemoved,
        }
      case AssetHistoryDataType.SENSOR_ADDED:
        return {
          icon: <Siren />,
          title: assets.history.sensorAdded,
        }
    }
  }

  return {
    getHistoryType,
  }
}

export const HistoryContent = ({ historyData }: HistoryContentProps) => {
  const { getHistoryType } = useHistoryType()

  return (
    <>
      {historyData.map((data, index) => {
        const { icon, title } = getHistoryType(data.type)

        return (
          <React.Fragment key={index}>
            <Card
              title={title}
              icon={icon}
              date={new Date(data.createdAt)}
              type='notification'
            >
              {data.description}
            </Card>
            <Divider />
          </React.Fragment>
        )
      })}
    </>
  )
}
