import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

export const SensorContainer = styled.div`
  padding: 0 16px 16px 16px;
`
