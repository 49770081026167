import { PropsWithChildren } from "react"
import { Column } from "../.."

import { Row, ContentRow, Cell } from "./styles"
import { Skeleton } from "@mui/material"

interface CellRowProps<T> {
  columns: Column<T>[]
  row: number
  checked?: boolean
}

export const CellRow = <T extends { id: number }>({
  columns,
  checked,
  children,
}: PropsWithChildren<CellRowProps<T>>) => {
  const hasCheckbox = checked !== undefined

  return (
    <>
      <Row $rowsize={columns.length} $checkbox={hasCheckbox}>
        {hasCheckbox && (
          <Cell>
            <Skeleton variant='circular' width={20} height={20} />
          </Cell>
        )}

        {columns.map((column) => (
          <Cell key={column.label} data-label={column.label}>
            <Skeleton variant='text' height={20} />
          </Cell>
        ))}
      </Row>

      <ContentRow $rowsize={1} $checkbox={hasCheckbox}>
        {children}
      </ContentRow>
    </>
  )
}
