import styled from "styled-components"

export const Table = styled.table`
  width: 100%;
  font-size: 0.8em;
  td {
    text-align: center;
  }
`

export const HeaderRow = styled.tr`
  background-color: #f7f7f7;
`

export const SensorHeader = styled.th`
  font-size: medium;
`

export const ServiceHeader = styled.th`
  font-size: medium;
  text-align: center;
`

export const Row = styled.tr`
  border-bottom: 1px solid #ececec;
`

export const SensorName = styled.td`
  font-size: medium;
`

export const Label = styled.label`
  gap: 8px;
  cursor: pointer;
  display: flex;
  font-size: medium;
`

export const TableHeader = styled.thead``

export const TableBody = styled.tbody``

export const ContainerService = styled.td`
  padding: 10px;
`

export const CheckboxService = styled.input``
