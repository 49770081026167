import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react"
import { onAlarmCacheEntryAdded } from "./updates"

export const adlApi = createApi({
  reducerPath: "adlApi",
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    listenAlarms: builder.query<void, number>({
      query: (_) => "adl/listenAlarms",
      onCacheEntryAdded: onAlarmCacheEntryAdded,
    }),
  }),
})

export const { useListenAlarmsQuery } = adlApi
