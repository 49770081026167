import { useContext } from "react"
import { SensorsConfigContext } from "../../../../../pages/SensorsConfig/context/SensorConfigContext"
import { SensorsConfigContextDTO } from "../../../../../pages/SensorsConfig/entities/SensorsConfigContextDTO"
import { sendResetRequest } from "../../../../../store/features/sensors/sensors.utilities"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"
import { toast } from "react-toastify"
import { HDR_SERVICES_TYPE } from "hdr-process-data"

export const useModalDisableOrResetController = () => {
  const {
    resetOnlyAlgorithm,
    typeOfServiceSelected,
    resetRequest,
    setBtnStatusDisable,
    btnStatusDisable,
    btnStatusReset,
    setBtnStatusReset,
    TRANSLATE_HDR_SERVICE_TYPE_INTO_PLAIN_PORTUGUESE,
    handleACKTimeout,
  } = useContext(SensorsConfigContext) as SensorsConfigContextDTO

  const { sensorsConfig } = useAppTranslate()

  const _getModalContentText = (): JSX.Element => {
    const resetOnlyAlgorithmText = (
      <>
        {sensorsConfig.disable_message_1}
        {typeOfServiceSelected.current &&
          `${TRANSLATE_HDR_SERVICE_TYPE_INTO_PLAIN_PORTUGUESE[typeOfServiceSelected.current]} `}
        {sensorsConfig.disable_message_2}
      </>
    )
    const resetAllSensorsText = <>{sensorsConfig.reset_message}</>
    return resetOnlyAlgorithm ? resetOnlyAlgorithmText : resetAllSensorsText
  }

  const removeAllExtraConfigFromLocalStorage = (mac: string): void => {
    ;["pot", "_4t20"].forEach((serviceName) => {
      localStorage.removeItem(`${mac} - ${serviceName} - ExtraConfig`)
      localStorage.removeItem(`${mac} - ${serviceName} - Reference`)
    })
  }

  const removeExtraConfigFromLocalStorage = (
    mac: string,
    algorithm: number
  ): void => {
    if (!HDR_SERVICES_TYPE[algorithm]) return

    localStorage.removeItem(
      `${mac} - ${HDR_SERVICES_TYPE[algorithm]} - ExtraConfig`
    )
    localStorage.removeItem(
      `${mac} - ${HDR_SERVICES_TYPE[algorithm]} - Reference`
    )
  }

  const resetOnlyAlgorithmFunction = async (): Promise<void> => {
    setBtnStatusDisable("disabling")
    const result = await sendResetRequest(resetRequest)
    if (result.success && resetRequest.algorithm) {
      removeExtraConfigFromLocalStorage(
        resetRequest.mac,
        resetRequest.algorithm
      )
      handleACKTimeout(() => {
        setBtnStatusDisable("error")
      })
    } else {
      toast.error(result.message, {
        autoClose: 3500,
        style: { fontSize: "1.5rem" },
      })
      setBtnStatusReset("error")
      setBtnStatusDisable("error")
    }
  }

  const resetSensorFunction = async () => {
    setBtnStatusReset("resetting")
    const result = await sendResetRequest(resetRequest)
    if (result.success) {
      removeAllExtraConfigFromLocalStorage(resetRequest.mac)
      handleACKTimeout(() => {
        setBtnStatusReset("error")
      })
    } else {
      setBtnStatusReset("error")
      setBtnStatusDisable("error")
    }
  }

  const onYesClick = () => {
    if (resetOnlyAlgorithm) {
      resetOnlyAlgorithmFunction()
    } else {
      resetSensorFunction()
    }
  }

  return {
    _getModalContentText,
    onYesClick,
    btnStatusDisable,
    btnStatusReset,
    resetOnlyAlgorithm,
  }
}
