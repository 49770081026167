import styled from "styled-components"

export const Content = styled.div<{ $depth: number }>`
  display: flex;
  gap: 4px;
  align-items: center;
  margin-top: 11px;
  margin-bottom: 11px;

  margin-left: ${({ $depth }) =>
    $depth === 0 ? "10px" : `${$depth * (16 + 4) + 10}px`};

  box-sizing: border-box;
`

export const ItemContainer = styled.div<{ $selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  font-size: ${({ theme }) => theme.typography.text.sm.fontSize};
  font-family: ${({ theme }) => theme.typography.text.sm.fontFamily};
  line-height: ${({ theme }) => theme.typography.text.sm.lineHeight};
  font-weight: ${({ theme }) => theme.typography.fontWeightSemibold};

  color: ${({ theme }) => theme.palette.gray[700]};

  background-color: ${({ $selected, theme }) =>
    $selected ? theme.palette.gray[100] : theme.palette.gray[50]};

  &:hover {
    background-color: ${({ theme }) => theme.palette.gray[300]};
  }

  &:hover svg {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  cursor: pointer;
`

export const ContainerWithChildren = styled.div`
  background-color: ${({ theme }) => theme.palette.gray[50]};
  width: 100%;
`

export const Wrapper = styled.li`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const ExpandAssetButton = styled.div<{ $expanded: boolean }>`
  transform: ${({ $expanded }) => ($expanded ? "rotate(0)" : "rotate(-90deg)")};

  color: ${({ theme }) => theme.palette.gray[500]};
`

export const AddAssetButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding-left: 10px;
  padding-right: 10px;

  color: ${({ theme }) => theme.palette.gray[500]};
`
