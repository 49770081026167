import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  font-size: medium;
  gap: 4px;
  color: ${({ theme }) => theme.palette.gray[500]};
  justify-content: center;
  justify-items: center;
  height: auto;
`
export const TitleArea = styled.div`
  height: 22px;
  display: flex;
  align-items: start;
`
