import { MFooter } from "../../styles"
import { Button } from "../../../ui/Button"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { FlexContainer } from "../../../ui/FlexContainer"
import { useMemo } from "react"

interface IModalFooter {
  disableAction?: boolean
  buttonTitle: string
  onAction?: () => void
  onBack?: () => void
  onNext?: () => void
  onCancel?: () => void
}

export const ModalFooter = ({
  buttonTitle,
  onAction,
  onBack,
  onNext,
  onCancel,
  disableAction = false,
}: IModalFooter) => {
  const { buttons } = useAppTranslate()

  const justify = useMemo(() => {
    const count = [onAction, onBack, onNext, onCancel].filter(
      (item) => item
    ).length

    if (count === 1) return "center"
    else return "space-between"
  }, [onAction, onBack, onNext, onCancel])

  return (
    <MFooter>
      <FlexContainer direction='row' justify={justify}>
        {onBack && (
          <Button onClick={onBack} variant='secondary'>
            {buttons.back}
          </Button>
        )}

        {onCancel && (
          <Button onClick={onCancel} variant='secondary'>
            {buttons.cancel}
          </Button>
        )}

        {onNext && (
          <Button onClick={onNext} disabled={disableAction} variant='secondary'>
            {buttons.next}
          </Button>
        )}

        {onAction && (
          <Button onClick={onAction} disabled={disableAction} variant='primary'>
            {buttonTitle}
          </Button>
        )}
      </FlexContainer>
    </MFooter>
  )
}
