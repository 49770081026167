/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from "react"
import { CaretDown } from "phosphor-react"
import { CellContent, TableElement } from "../../tableCss.styled"
import { CheckBoxUI } from "../Check"
import { ListDetailsGateways } from "../../../../../pages/v3/Gateways/styles/styles"
import { OnOff } from "../../../../../pages/v3/Gateways/components/OnOff"
import { useTableCss } from "../../hooks/useTableCss"
import { MobileContent, DetailTitle, DetailText, MobileDetails } from "./styles"
import { Text } from "../../../../ui/Text"

interface RowTableProps<T, U, W> {
  row: T
  config: U[]
  onChange: (value: T, check: boolean) => void
  renderExpandContent: W[]
  resetCheckboxes?: boolean
}

export const RowsTableCss = <
  T extends Record<string, any>,
  U extends Record<string, any>,
  W extends Record<string, any>,
>(
  props: RowTableProps<T, U, W>
) => {
  const { row, config, onChange, renderExpandContent, resetCheckboxes } = props

  const { expandedRow, showRow, checked, setChecked } = useTableCss<T>()

  useEffect(() => {
    setChecked(false)
  }, [resetCheckboxes, setChecked])

  let statusGateway: boolean = false
  let nowInMilliseconds = new Date().getTime()

  if (row.value?.time) {
    let date = new Date(row.value.time * 1000).getTime()
    statusGateway = nowInMilliseconds - date <= 15000
  }

  const handleCheckboxChange = () => {
    const newChecked = !checked
    setChecked(newChecked)
    onChange(row, newChecked)
  }

  const renderedCells = config.map((column, index) => {
    let showOnOff = column.label === "Gateway"
    const clName = `mobile-view-over ${showOnOff ? "gateway-name" : ""}`

    return (
      <div key={column.label} className='cell-column'>
        <span className='mobile-view'>{column.label}</span>
        <div className={clName}>
          {showOnOff && <OnOff status={statusGateway} />}
          <TableElement $idx={index}>{column.render(row)}</TableElement>
        </div>
      </div>
    )
  })

  const renderDetails = renderExpandContent.map((item) => {
    return (
      <div key={item.label} className='list-gateway-info'>
        <span>{item.label} </span>
        <p>{item.render(row)}</p>
      </div>
    )
  })

  return (
    <>
      <div className='table-row' key={row.id}>
        <div className='cell-column'>
          <CheckBoxUI isCheck={checked} onClick={handleCheckboxChange} />
        </div>
        {renderedCells}

        <MobileContent $isOpen={expandedRow}>
          <div>
            {renderExpandContent.map((item) => {
              return (
                <MobileDetails key={item.label}>
                  <DetailTitle>
                    <Text color='gray.700' fontSize='sm' fontWeight='bold'>
                      {item.label}
                    </Text>
                  </DetailTitle>

                  <DetailText>
                    <Text color='gray.700' fontSize='sm' fontWeight='regular'>
                      {item.render(row)}
                    </Text>
                  </DetailText>
                </MobileDetails>
              )
            })}
          </div>
        </MobileContent>

        <div className='cell-column expand-content-mobile'>
          <CellContent $isOpen={expandedRow} onClick={showRow}>
            <div className='arrow-container'>
              <CaretDown size={22} />
            </div>
          </CellContent>
        </div>
      </div>
      <ListDetailsGateways $isOpen={expandedRow}>
        <div className='table-row-expand-group'>
          <div className='list-items'>{renderDetails}</div>
        </div>
      </ListDetailsGateways>
    </>
  )
}
