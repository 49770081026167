import { Children, useContext } from "react"
import { TabsContext } from "../Tabs"

export const TabPanels = ({
  children,
  ...props
}: React.ComponentProps<"div">) => {
  const state = useContext(TabsContext)

  if (!state) return <></>

  return Children.map(children, (child, index) => {
    if (index === state.selectedIndex) return child
  })
}
