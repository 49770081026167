import { createSlice } from "@reduxjs/toolkit"

export interface SocketState {
  isConnected: boolean
  messages: []
}

export interface ChatMessage {
  id: number
  content: string
}

const initialState: SocketState = {
  isConnected: false,
  messages: [],
}

const socketConnectionSlice = createSlice({
  name: "socketConnection",
  initialState,
  reducers: {
    initSocket: (state) => {
      return
    },
    connectionEstablished: (state) => {
      state.isConnected = true
    },
    connectionLost: (state) => {
      state.isConnected = false
    },
  },
})

export const { initSocket, connectionEstablished, connectionLost } =
  socketConnectionSlice.actions
export default socketConnectionSlice.reducer
