export type GatewayItem = {
  id: number
  user_id: number
  collector_id: number
  company_id: number
  name: string
  ble_hdr_mac: string
  ble_original_mac: string
  created_at: string
  updated_at: string
}

export interface IGatewaysList {
  gateways: GatewayItem[]
}

export type GatewaysTable = {
  id: number
  name: string
  bleHdrMac: string
  rssi: number
  mac: string
  ip: string
  version: string
  time: number
  companyId: number
  ewMac: string
  value: {
    rssi: number
    lifetime: number
    dataRate: number
    ip_wifi: string
    mac: string
    ewMac: string
    version: string
    ssid: string
    time: number
  }
}

export type GatewaysTableConfiguration = [
  {
    label: string
    render: (param: GatewaysTable) => string
  },
  {
    label: string
    render: (param: GatewaysTable) => string
  },
  {
    label: string
    render: (param: GatewaysTable) => string
  },
  {
    label: string
    render: (param: GatewaysTable) => string
  },
  {
    label: string
    render: (param: GatewaysTable) => string
  },
]

export const getGatewayTableRow = ({
  dataRateLabel,
  activeTimeLabel,
}: {
  dataRateLabel: string
  activeTimeLabel: string
}): GatewaysTableConfiguration => {
  return [
    {
      label: "Gateway",
      render: (data: GatewaysTable) => data.name,
    },
    {
      label: "SSID",
      render: (data: GatewaysTable) => {
        const ssid = data.value?.ssid

        return ssid ? `${ssid}` : ` - `
      },
    },
    {
      label: "RSSI",
      render: (data: GatewaysTable) => {
        const rssi = data.value?.rssi

        return rssi ? `${rssi} dBM` : `0 dBM`
      },
    },
    {
      label: dataRateLabel,
      render: (data: GatewaysTable): string => {
        const tDate = data.value?.dataRate

        return tDate ? `-${tDate} dBM` : `0 dBM`
      },
    },
    {
      label: activeTimeLabel,
      render: (data: GatewaysTable): string => {
        const gatewayTime = data.value?.lifetime
        if (gatewayTime) {
          const hours = Math.floor(gatewayTime / 3600)
          const minutes = Math.floor((gatewayTime % 3600) / 60)
          const seconds = Math.floor((gatewayTime % 3600) % 60)

          return `${hours}:${minutes}:${seconds}`
        }

        return "00:00:00"
      },
    },
  ]
}

export type GatewayDetailsConfiguration = [
  {
    label: string
    render: (param: GatewaysTable) => string
  },
  {
    label: string
    render: (param: GatewaysTable) => string
  },
  {
    label: string
    render: (param: GatewaysTable) => string
  },
  {
    label: string
    render: (param: GatewaysTable) => string
  },
  {
    label: string
    render: (param: GatewaysTable) => string
  },
]

export const getGatewayTableRowDetails = ({
  firmwareVersionLabel,
}: {
  firmwareVersionLabel: string
}): GatewayDetailsConfiguration => [
  {
    label: "IP WIFI: ",
    render: (data: GatewaysTable) => {
      const ip = data.value?.ip_wifi

      return ip ? `${ip}` : ` - `
    },
  },
  {
    label: "BLE HDR-MAC: ",
    render: (data: GatewaysTable) => {
      const mac = data.value?.mac

      return mac ? `${mac}` : ` - `
    },
  },
  {
    label: "MAC WIFI: ",
    render: (data: GatewaysTable) => {
      const ewMac = data.ewMac

      return ewMac ? `${ewMac}` : ` - `
    },
  },
  {
    label: firmwareVersionLabel,
    render: (data: GatewaysTable) => {
      const version = data.value?.version

      return version ? `${version}` : ` - `
    },
  },
  {
    label: "MQTT ID: ",
    render: (data: GatewaysTable) => {
      const mqttId = data.id

      return mqttId ? `${mqttId}` : ` - `
    },
  },
]
