import { SPageTitle } from "./styled"

type PageProps = {
  title: string
  subTitle?: string
}

export const PageTitle = ({ title, subTitle }: PageProps) => {
  return (
    <SPageTitle>
      <h4 className='m-l-10 page-title'>{title}</h4>
      <h6 className='m-l-10 page-sub-title'>{subTitle}</h6>
    </SPageTitle>
  )
}
