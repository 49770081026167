import { FC } from "react"
import * as S from "./styles"
import { AnalyticGridOption } from "../AnalyticGridOption"
import { Title } from "../../../../../Title"

interface SelectAnalyticGridOptionProps {
  typeLayoutLabel: string
  selectedIndex: number
  onChange: (index: number) => void
  oneAnalyticLabel: string
  twoAnalyticLabel: string
  threeAnalyticLabel: string
}

export const SelectAnalyticGridOption: FC<SelectAnalyticGridOptionProps> = ({
  typeLayoutLabel,
  selectedIndex,
  onChange,
  oneAnalyticLabel,
  twoAnalyticLabel,
  threeAnalyticLabel,
}) => {
  return (
    <S.Container>
      <Title label={typeLayoutLabel} size='sm' />
      <S.OptionsGroup>
        <S.Option onClick={() => onChange(1)} selected={selectedIndex === 1}>
          <S.InputOption
            type='radio'
            checked={selectedIndex === 1}
            onChange={() => onChange(1)}
          />
          <S.OptionAndSubtitle>
            <AnalyticGridOption analyticsPerLine={1} />
            <S.Text>{oneAnalyticLabel}</S.Text>
          </S.OptionAndSubtitle>
        </S.Option>
        <S.Option onClick={() => onChange(2)} selected={selectedIndex === 2}>
          <S.InputOption
            type='radio'
            checked={selectedIndex === 2}
            onChange={() => onChange(2)}
          />
          <S.OptionAndSubtitle>
            <AnalyticGridOption analyticsPerLine={2} />
            <S.Text>{twoAnalyticLabel}</S.Text>
          </S.OptionAndSubtitle>
        </S.Option>
        <S.Option onClick={() => onChange(3)} selected={selectedIndex === 3}>
          <S.InputOption
            type='radio'
            checked={selectedIndex === 3}
            onChange={() => onChange(3)}
          />
          <S.OptionAndSubtitle>
            <AnalyticGridOption analyticsPerLine={3} />
            <S.Text>{threeAnalyticLabel}</S.Text>
          </S.OptionAndSubtitle>
        </S.Option>
      </S.OptionsGroup>
    </S.Container>
  )
}
