import styled from "styled-components"

export const TreeAssetsHeader = styled.div`
  padding: 16px 0px 0px 16px;
`

export const InputContainer = styled.div`
  padding: 0px 16px;
  display: flex;
  gap: 5px;
`
