import { ReactNode } from "react"

interface IconComponentProps {
  children: ReactNode
  className?: string
}

export function IconComponent(props: IconComponentProps) {
  return (
    <div className={props.className}>
      <>{props.children}</>
    </div>
  )
}
