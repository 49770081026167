import { useFetchAssetDetailsQuery } from "../../../../../../store/store"
import { useAppTranslate } from "../../../../../../translate/useAppTranslate"
import { MessageContainer } from "../../styles"
import { HistoryCardSkeleton } from "../Skeleton/HistoryCard"
import { HistoryContent } from "./components/HistoryContent"
import { HistoryHeader } from "./components/HistoryHeader"
import { Container } from "./styles"

interface HistoryCardProps {
  assetId: number
}

export const HistoryCard = ({ assetId }: HistoryCardProps) => {
  const { assets } = useAppTranslate()

  const { data, isError, isFetching } = useFetchAssetDetailsQuery(assetId)

  if (isError)
    return <MessageContainer>{assets.history.error}</MessageContainer>

  if (isFetching) return <HistoryCardSkeleton />

  return (
    <Container>
      <HistoryHeader />
      {data && <HistoryContent historyData={data.historicalData} />}
    </Container>
  )
}
