import Row from "../../../../components/ui/Row"
import Column from "../../../../components/ui/Column"
import { AssetForm } from "../components/AssetForm"
import { useForm } from "react-hook-form"
import { AssetSchema, assetSchema, defaultValues } from "../utils/schema"
import { zodResolver } from "@hookform/resolvers/zod"
import { Actions } from "./components/Actions"

interface CreateAssetProps {
  handleCancel: () => void
  handleCreate: (data: AssetSchema, parentId: string) => void
  isLoading: boolean
  assetId: string
}

export const CreateAsset = ({
  handleCancel,
  handleCreate,
  assetId,
  isLoading,
}: CreateAssetProps) => {
  const form = useForm<AssetSchema>({
    resolver: zodResolver(assetSchema),
    defaultValues,
  })
  return (
    <>
      <Row>
        <Column md='24' lg='24' xl='24'>
          <AssetForm form={form} />
        </Column>
      </Row>

      <Actions
        handleFormSubmit={form.handleSubmit}
        handleCancel={handleCancel}
        handleCreate={handleCreate}
        isLoading={isLoading}
        assetId={assetId}
      />
    </>
  )
}
