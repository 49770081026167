import styled from "styled-components"

export const Wrapper = styled.div`
  height: 100%;
`

export const Content = styled.div`
  height: calc(100% - 12px - 52px - 26px - 24px);
  margin: 0px 12px;
`
