import { useGetMessagesQuery } from "../store/store"

export const useWebSocket = () => {
  const messages = (gat: string) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { data } = useGetMessagesQuery(gat)

    return data
  }

  return { messages }
}
