import { FC } from "react"
import * as S from "./styles"
import { Title } from "../Title"

interface TitleInputTextProps {
  title: string
  type: string
  name: string
  placeholder: string
  onChange?: (value: string) => void
  value?: string
}

export const TitleInputText: FC<TitleInputTextProps> = ({
  title,
  type,
  name,
  placeholder,
  onChange,
  value,
}) => {
  return (
    <S.Container data-testid='title-input'>
      <Title label={title} size='sm' />
      <S.InputTitleAnalytic
        value={value}
        type={type}
        name={name}
        placeholder={placeholder}
        onChange={(e) => {
          if (onChange) onChange(e.target.value)
        }}
      />
    </S.Container>
  )
}
