import { Skeleton } from "@mui/material"
import * as S from "./styles"

type IconNotificationProps = {
  status: "read" | "unread"
  icon: JSX.Element | undefined
  type?: "notification" | "message"
  skeleton?: boolean
}

export const CardIcon = ({
  status = "read",
  icon,
  type = "notification",
  skeleton = false,
}: IconNotificationProps) => {
  return (
    <div>
      <S.RoundDiv $type={type} $status={status}>
        {!skeleton ? (
          icon
        ) : (
          <Skeleton variant='circular' width={"100%"} height={"100%"} />
        )}
      </S.RoundDiv>
    </div>
  )
}
