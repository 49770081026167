import React from "react"
import styled from "styled-components"
import { media } from "../../../utils/media-queries"

export const getWidthString = (value?: number) => {
  if (!value) return

  return ((value / 24) * 100).toString() + "%"
}

type Bp = {
  $sm?: string
  $md?: string
  $lg?: string
  $xl?: string
}

const SColumn = styled.div<Bp>`
  float: left;
  min-height: 1px;
  box-sizing: border-box;

  width: 100%;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  @media ${media.medium} {
    width: ${({ $md }) => getWidthString(Number($md))};
  }

  @media ${media.large} {
    width: ${({ $lg }) => getWidthString(Number($lg))};
  }

  @media ${media.extraLarge} {
    width: ${({ $xl }) => getWidthString(Number($xl))};
  }
`

type ColumnProps = {
  sm?: string
  md?: string
  lg?: string
  xl?: string
  children: React.ReactNode
}

const Column: React.FC<ColumnProps> = ({ children, sm, md, lg, xl }) => {
  return (
    <SColumn $sm={sm} $md={md} $lg={lg} $xl={xl}>
      {children}
    </SColumn>
  )
}

export default Column
