import styled from "styled-components"

export const Container = styled.div`
  height: 20px;
  width: 20px;
  display: flex;
  input {
    height: 20px;
    width: 20px;
    border-radius: 4px;
    accent-color: ${(props) => props.theme.palette.primary.main};
  }
`
