import styled from "styled-components"

type CaretIconProps = {
  $isOpen: boolean
}

export const IconContainer = styled.div<CaretIconProps>`
  height: 20px;
  width: 20px;

  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transform: ${({ $isOpen }) =>
      !$isOpen ? "rotate(0deg)" : "rotate(-180deg)"};
  }
`
