import { css } from "styled-components"
import { typography } from "../theme/proteu/typography"

interface IFontsProps {
  fontWeight: string
  size: string
  family?: string
  lineHeight?: string
  color?: string
}

const setFontSize = (type: string) => {
  switch (type) {
    case "display.xs":
      return typography.display.xs.fontSize
    case "text.lg":
      return typography.text.lg.fontSize
    case "text.md":
      return typography.text.md.fontSize
    case "text.sm":
      return typography.text.sm.fontSize
    case "text.xs":
      return typography.text.xs.fontSize
    default:
      return "10px"
  }
}

const setLineHeight = (type: string) => {
  switch (type) {
    case "display.xs":
      return typography.display.xs.lineHeight
    case "text.lg":
      return typography.text.lg.lineHeight
    case "text.md":
      return typography.text.md.lineHeight
    case "text.sm":
      return typography.text.sm.lineHeight
    case "text.xs":
      return typography.text.xs.lineHeight
    default:
      return
  }
}

const setFontWeight = (type: string) => {
  switch (type) {
    case "regular":
      return typography.fontWeightRegular
    case "medium":
      return typography.fontWeightMedium
    case "semibold":
      return typography.fontWeightSemibold
    case "bold":
      return typography.fontWeightBold
    default:
      return 300
  }
}

export const Font = ({
  family,
  size,
  lineHeight,
  fontWeight,
  color,
}: IFontsProps) => css`
  font-family: ${family || typography.fontFamily};
  font-size: ${() => setFontSize(size)};
  line-height: ${() => setLineHeight(lineHeight || "")};
  font-weight: ${() => setFontWeight(fontWeight)};
  color: ${color};
`
