import styled from "styled-components"
import { media } from "../../../../../utils/media-queries"

export const Container = styled.div`
  display: flex;
  padding: 16px;
  gap: 16px;
  flex-direction: column;

  @media ${media.small} {
    flex-direction: row;
  }
`
