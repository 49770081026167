import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  gap: 2px;
  width: 100%;
`

export const STextArea = styled.textarea`
  width: 100%;

  background-color: ${({ theme }) => theme.palette.gray[50]};

  outline: none;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  border: 1px solid ${({ theme }) => theme.palette.gray[300]};
  border-radius: 4px;

  padding: 2px;

  resize: none;

  overflow-y: scroll;

  &::placeholder {
    color: ${(props) => props.theme.palette.gray[500]};
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: ${(props) => props.theme.typography.text.sm.fontSize};
    font-weight: ${(props) => props.theme.typography.fontWeightRegular};
    line-height: ${(props) => props.theme.typography.text.sm.lineHeight};
  }

  &:focus {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
  }
`

export const Label = styled.p<{ $disable: boolean | undefined }>`
  color: ${({ $disable, theme }) =>
    $disable ? theme.palette.gray[500] : theme.palette.gray[600]};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.text.xs.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightSemibold};
  line-height: ${(props) => props.theme.typography.text.xs.lineHeight};
`

export const HelperText = styled.p`
  color: ${(props) => props.theme.palette.gray[400]};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.text.xs.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  line-height: ${(props) => props.theme.typography.text.xs.lineHeight};
`

export const ErrorText = styled.p`
  color: ${(props) => props.theme.palette.error.main};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.text.xs.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  line-height: ${(props) => props.theme.typography.text.xs.lineHeight};
`
