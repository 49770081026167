import { TableCssSkeleton } from "../../../../../components/ui/TableCss/TableCss.skeleton"
import {
  getGatewayTableRow,
  getGatewayTableRowDetails,
} from "../../utils/gatewaysDataTable"
import { TableCss } from "../../../../../components/ui/TableCss"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"
import { Dispatch, SetStateAction } from "react"
import { IGatewayDataTable } from "../../../../../store/features/gateways/gateways.interfaces"
import { ErrorMessage } from "../../../Assets/components/ErrorMessage"

interface GatewayContentProps {
  handleOpenModal: () => void
  setSelectedItems: Dispatch<SetStateAction<IGatewayDataTable[]>>
  listGateways: IGatewayDataTable[]
  error: boolean
  isFetching: boolean
  refetch: () => void
}

export const GatewayContent = ({
  handleOpenModal,
  setSelectedItems,
  listGateways,
  error,
  isFetching,
  refetch,
}: GatewayContentProps) => {
  const { gateways, buttons } = useAppTranslate()

  const configuration = getGatewayTableRow({
    dataRateLabel: gateways.dataRate,
    activeTimeLabel: gateways.activeTime,
  })

  const tableRowDetails = getGatewayTableRowDetails({
    firmwareVersionLabel: gateways.firmwareVersion,
  })

  if (isFetching) {
    return <TableCssSkeleton configuration={configuration} itens={5} />
  }

  if (error)
    return <ErrorMessage message={gateways.error} handleReload={refetch} />

  return (
    <TableCss
      items={listGateways}
      configuration={configuration}
      renderExpand={tableRowDetails}
      functions={[
        {
          label: `${buttons.rename}`,
          onClick: (selectedItems) => {
            handleOpenModal()
            setSelectedItems(selectedItems)
          },
          type: "single",
          variant: "secondary",
        },
      ]}
    />
  )
}
