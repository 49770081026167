import { Skeleton } from "@mui/material"

interface RowTableProps<U> {
  config: U[]
}
interface TableConfigItem {
  label: string
}
export const RowsTableCssSkeleton = <U extends TableConfigItem>(
  props: RowTableProps<U>
) => {
  const { config } = props

  const renderedCells = config.map((column, index) => {
    return (
      <div key={column.label} className='cell-column'>
        <span className='mobile-view'>{column.label}</span>
        <div className='mobile-view-over'>
          <Skeleton variant='rounded' width={100} height={20} />
        </div>
      </div>
    )
  })

  return (
    <div className='table-row'>
      <div className='cell-column' />
      {renderedCells}
      <div className='cell-column expand-content-mobile' />
    </div>
  )
}
