import styled from "styled-components"
import { media } from "../../../../../../utils/media-queries"

export const Container = styled.div`
  padding: 16px 16px;

  display: flex;
  flex-direction: column;
  justify-content: start;

  margin: 0 12px;

  @media ${media.large} {
    margin: 0;
  }
`
