import { useMemo } from "react"
import { BoxBar, BoxBarContainer } from "../../tableCss.styled"
import { TableButton } from "../.."
import { Button } from "../../../Button"
import { WrapperButtons } from "./styles"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"

type TableBarProps<T> = {
  count: number
  functions?: TableButton<T>[]
  items: T[]
  uncheckAll: () => void
}

export const BoxActionTable = <T,>({
  count,
  functions,
  items,
  uncheckAll,
}: TableBarProps<T>) => {
  const showBox = useMemo(() => count > 0, [count])
  const { gateways, buttons } = useAppTranslate()

  const canRenderButton = (func: TableButton<T>) => {
    return !(func.type === "single" && count > 1)
  }
  return (
    <BoxBar $iShow={showBox}>
      <BoxBarContainer>
        <div>
          <p>
            {gateways.selecteds}: {count}
          </p>
        </div>
        <WrapperButtons>
          {functions?.map(
            (func, index) =>
              canRenderButton(func) && (
                <Button
                  variant={func.variant}
                  key={index}
                  onClick={() => func.onClick(items)}
                >
                  {func.label}
                </Button>
              )
          )}

          <Button variant='link' onClick={uncheckAll}>
            {buttons.cancel}
          </Button>
        </WrapperButtons>
      </BoxBarContainer>
    </BoxBar>
  )
}
