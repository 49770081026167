import styled from "styled-components"

export const ButtonsContainer = styled.div<{ $isSingleButton: boolean }>`
  width: 100%;
  padding: 16px 16px 0px 16px;
  display: flex;
  align-items: center;
  height: 100%;
  margin: auto;
  flex-direction: row;
  justify-content: ${({ $isSingleButton }) =>
    $isSingleButton ? "center" : "space-between"};
`
