import { Gear } from "@phosphor-icons/react"
import { Button } from "../../../../../../../components/ui/Button"
import { Container } from "./styles"
import { IconButton } from "../../../../../../../components/ui/IconButton"
import { useAppTranslate } from "../../../../../../../translate/useAppTranslate"

interface ConfigureAssetButtonProps {
  handleConfigure: () => void
}

export const ConfigureAssetButton = ({
  handleConfigure,
}: ConfigureAssetButtonProps) => {
  const { buttons } = useAppTranslate()

  return (
    <Container>
      <Button
        className='desktop-button'
        variant='secondary'
        onClick={handleConfigure}
      >
        {buttons.configure}
      </Button>
      <IconButton
        className='mobile-icon-button'
        variant='secondary'
        icon={<Gear size={22} />}
        onClick={handleConfigure}
      />
    </Container>
  )
}
