import { ComponentProps } from "react"
import { Container } from "./styles"

interface CheckboxProps extends Omit<ComponentProps<"input">, "type"> {
  type?: "checkbox" | "radio"
}

export const Checkbox = ({ type = "checkbox", ...props }: CheckboxProps) => {
  return (
    <Container>
      <input type={type} {...props} />
    </Container>
  )
}
