import Row from "../../../../components/ui/Row"
import { useFetchAssetDetailsQuery } from "../../../../store/store"
import Column from "../../../../components/ui/Column"
import { useForm } from "react-hook-form"
import { assetSchema, AssetSchema, getDefaultValues } from "../utils/schema"
import { zodResolver } from "@hookform/resolvers/zod"
import { useEffect } from "react"
import { AssetForm, AssetFormSkeleton } from "../components/AssetForm"
import { Actions } from "./components/Actions"
import { ErrorMessage } from "../components/ErrorMessage"
import { useAppTranslate } from "../../../../translate/useAppTranslate"

interface ConfigureProps {
  id: number
  handleDelete: () => void
  handleFormSubmit: (data: AssetSchema) => Promise<void>
  isDisable: boolean
}

export const ConfigureAsset = ({
  id,
  handleDelete,
  handleFormSubmit,
  isDisable,
}: ConfigureProps) => {
  return (
    <Configure
      id={id}
      handleDelete={handleDelete}
      handleFormSubmit={handleFormSubmit}
      isDisable={isDisable}
    />
  )
}

const Configure = ({
  id,
  handleDelete,
  handleFormSubmit,
  isDisable,
}: ConfigureProps) => {
  const {
    data: details,
    isLoading,
    isError,
    refetch,
  } = useFetchAssetDetailsQuery(id)

  const { assets } = useAppTranslate()

  const form = useForm<AssetSchema>({
    resolver: zodResolver(assetSchema),
  })

  useEffect(() => {
    if (!details) return
    form.reset(getDefaultValues(details))
  }, [details, form])

  return (
    <>
      <Row>
        <Column md='24' lg='24' xl='24'>
          {details && (
            <AssetForm
              form={form}
              isConfigure
              defaultValue={details.sensor?.serialNumber}
              isRoot={details.isRoot}
            />
          )}
          {isLoading && <AssetFormSkeleton />}
          {isError && (
            <ErrorMessage
              message={assets.details.error}
              handleReload={refetch}
            />
          )}
        </Column>
      </Row>
      {details && (
        <Actions
          id={id}
          isRoot={details.isRoot}
          handleFormSubmit={form.handleSubmit}
          handleDelete={handleDelete}
          handleSubmit={handleFormSubmit}
          isDisable={isDisable}
        />
      )}
    </>
  )
}
