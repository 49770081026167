import { Justify, Align, Container } from "./styles"

interface FlexContainerProps {
  direction?: "row" | "column"
  align?: Align
  justify?: Justify
  gap?: number
  children?: React.ReactNode
  fullWidth?: boolean
  fullHeight?: boolean
  padding?: string
}

export const FlexContainer = ({
  direction = "column",
  align = "center",
  justify = "center",
  gap = 0,
  fullWidth = false,
  fullHeight = false,
  children,
  padding,
}: FlexContainerProps) => {
  return (
    <Container
      $direction={direction}
      $align={align}
      $justify={justify}
      $gap={gap}
      $fullWidth={fullWidth}
      $fullHeigth={fullHeight}
      style={{ padding }}
    >
      {children}
    </Container>
  )
}
