import { InputHTMLAttributes, PropsWithRef, forwardRef } from "react"
import { Label, Container, Header, Option, Select, ErrorText } from "./styles"
import { Button } from "../Button"

interface SelectProps extends InputHTMLAttributes<HTMLSelectElement> {
  label?: string
  labelButton?: string
  error?: string
  placeholder: string
  options: (string | number)[]
  renderLabel?: (option: string | number) => string
}

export const InputSelect = forwardRef<
  HTMLSelectElement,
  PropsWithRef<SelectProps>
>(
  (
    {
      label,
      labelButton,
      disabled,
      error,
      placeholder,
      options,
      renderLabel,
      ...props
    },
    ref
  ) => {
    return (
      <Container>
        <Header>
          {label && <Label $disable={disabled}>{label}</Label>}
          {labelButton && <Button variant='link'>{labelButton}</Button>}
        </Header>
        <Select name='select' {...props} defaultValue='' required ref={ref}>
          <Option value={""} disabled>
            {placeholder}
          </Option>
          {options.map((option, index) => (
            <Option key={index} value={option}>
              {renderLabel ? renderLabel(option) : option}
            </Option>
          ))}
        </Select>

        {error && <ErrorText>{error}</ErrorText>}
      </Container>
    )
  }
)

InputSelect.displayName = "InputSelect"
