import { useEffect, useMemo, useRef, useState } from "react"
import { TreeRef } from "react-complex-tree"
import { useNavigate, useParams } from "react-router-dom"
import { useFetchAssetsQuery } from "../../../../../store/store"
import { AssetTreeData } from "../../../../../utils/entities/assets"
import { CustomDataProvider } from "../components/TreeAssets/CustomDataProvider"

export const useAssets = () => {
  const [open, setOpen] = useState(true)
  const [assetDetail, setAssetDetails] = useState<number | undefined>()
  const navigate = useNavigate()
  const tree = useRef<TreeRef>(null)
  const { id } = useParams<{ id: string }>()

  const {
    data: listAssets,
    isError,
    isLoading,
    isFetching,
    refetch,
  } = useFetchAssetsQuery()

  const canRenderTree = useMemo(
    () => !isError && !isLoading && !isFetching,
    [isError, isLoading, isFetching]
  )

  const dataProvider = useMemo(() => {
    if (!listAssets) {
      return new CustomDataProvider({})
    } else {
      return new CustomDataProvider({ ...listAssets })
    }
  }, [listAssets])

  useEffect(() => {
    if (!listAssets) return
    const assetsIds = Object.keys(listAssets)
    if (assetsIds.length === 0) return

    const assetIdToUse = getValidAssetId(listAssets, assetsIds, id)

    if (!listAssets[assetIdToUse]) {
      setAssetDetails(listAssets[assetsIds[0]].data.id)
    } else {
      setAssetDetails(listAssets[assetIdToUse].data.id)
    }
  }, [listAssets, id])

  const getValidAssetId = (
    listAssets: AssetTreeData,
    assetsIds: string[],
    id?: string
  ) => {
    if (id && listAssets[id]) {
      return id
    }
    return assetsIds[0]
  }

  const setClickAsset = (id: number) => {
    setAssetDetails(id)
    navigate(`/assets/${id}`)
  }

  const onClick = () => {
    setOpen((prev) => !prev)
  }

  return {
    open,
    assetDetail,
    tree,
    listAssets,
    isError,
    isLoading,
    isFetching,
    canRenderTree,
    dataProvider,
    refetch,
    setClickAsset,
    onClick,

    setAssetDetails,
    navigate,
    setOpen,
  }
}
