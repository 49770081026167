import { useAppTranslate } from "../../../../../translate/useAppTranslate"
import { Title } from "../../../../../components/Title"
import { TitleAndText } from "../../../../../components/TitleAndText"
import { ConfigureAssetButton } from "./components/ConfigAsset"
import {
  AssetHeader,
  Configurations,
  Container,
  ImageAndDescription,
} from "./styles"
import { ImageContainer } from "../ImageContainer"
import { useConfigureAsset } from "../../Configure/controllers/useConfigureAsset"
import { ModalHedro } from "../../../../../components/ModalHedro"
import { ConfigureAsset } from "../../Configure"

interface AssetDescriptionProps {
  description: string
  assetName: string
  assetId: number
}

export const AssetDescription = ({
  description,
  assetName,
  assetId,
}: AssetDescriptionProps) => {
  const { assets } = useAppTranslate()
  const {
    handleOpenConfigureAssetModal,
    handleCloseConfigureAssetModal,
    isModalConfigureAssetOpen,
    handleSubmit,
    handleDelete,
    isDisable,
  } = useConfigureAsset({ id: assetId })

  return (
    <Container>
      {isModalConfigureAssetOpen && (
        <ModalHedro
          onClose={handleCloseConfigureAssetModal}
          title={assets.modal.editAsset}
          size='xl'
        >
          <ConfigureAsset
            id={assetId}
            handleDelete={handleDelete}
            handleFormSubmit={handleSubmit}
            isDisable={isDisable}
          />
        </ModalHedro>
      )}

      <AssetHeader>
        <Title label={assetName} size='md' />
        <Configurations>
          <ConfigureAssetButton
            handleConfigure={handleOpenConfigureAssetModal}
          />
          {/* <Favorite /> */}
        </Configurations>
      </AssetHeader>
      <ImageAndDescription>
        <ImageContainer />
        <TitleAndText text={description} title={assets.details.description} />
      </ImageAndDescription>
    </Container>
  )
}
