import { Socket, io } from "socket.io-client"

export interface ISocket {
  socket: Socket
}

class SocketConnection implements ISocket {
  public socket: Socket
  public socketEndpoint = process.env.REACT_APP_WS_SERVER_URL ?? ""

  constructor(accessToken: string) {
    this.socket = io(this.socketEndpoint, {
      autoConnect: true,
      transports: ["websocket"],
      query: {
        accessToken: accessToken,
      },
    })
  }
}

let socketConnection: SocketConnection | undefined

class SocketFactory {
  public static create(): SocketConnection {
    const accessToken = sessionStorage.getItem(
      sessionStorage.key(0) as string
    ) as string

    if (!socketConnection) {
      socketConnection = new SocketConnection(accessToken)
    }

    return socketConnection
  }
}

export default SocketFactory
