import { CaretDoubleLeft, CaretDoubleRight } from "phosphor-react"
import { IconButton } from "../../../../../../components/ui/IconButton"
import {
  Container,
  AssetsContainer,
  AssetsHeader,
  IconContainer,
} from "./styles"

interface TreeContainerProps {
  onClick: () => void
  open: boolean
  children?: React.ReactNode
}

export const TreeContainer = ({
  onClick,
  open,
  children,
}: TreeContainerProps) => {
  return (
    <Container>
      <AssetsContainer open={open}>
        <AssetsHeader>
          <CaretDoubleLeft
            onClick={onClick}
            size={24}
            style={{ cursor: "pointer" }}
          />
        </AssetsHeader>
        {children}
      </AssetsContainer>

      <IconContainer open={open}>
        <IconButton
          icon={<CaretDoubleRight size={24} />}
          variant='secondary'
          onClick={onClick}
        />
      </IconContainer>
    </Container>
  )
}
