import { TextareaHTMLAttributes, forwardRef } from "react"
import { Container, ErrorText, HelperText, Label, STextArea } from "./styles"

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string
  helper?: string
  error?: string
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ label, helper, error, ...props }, ref) => {
    return (
      <Container>
        {label && <Label $disable={props.disabled}>{label}</Label>}

        <STextArea ref={ref} {...props} />

        {error ? (
          <ErrorText>{error}</ErrorText>
        ) : (
          helper && <HelperText>{helper}</HelperText>
        )}
      </Container>
    )
  }
)

TextArea.displayName = "TextArea"
