import * as S from "./styles"
import { FC } from "react"
import { BsX } from "react-icons/bs"
import { MdFileDownload } from "react-icons/md"
import { ToggleFFT } from "../../../../components/ToggleFFT"
import { useChartContainerController } from "./controller/useChartContainerController"
import { Chart } from "../../../../components/Chart"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { ValidatePermission } from "../../../../utils/validatePermission"
import {
  ChartData,
  ChartType,
} from "../../../../components/Chart/entities/ChartData"
import { PermissionsProteu } from "../../../../store/features/user/user.interfaces"

interface ChartContainerProps {
  chart: ChartData
  graphics_line: number
  onDelete: Function
  onEdit: Function
  onSaveADL: Function
}

export const ChartContainer: FC<ChartContainerProps> = ({
  chart,
  graphics_line,
  onDelete,
  onEdit,
  onSaveADL,
}) => {
  const { fftType, handleToggleFFT } = useChartContainerController({
    type: chart.type,
    id: chart.id,
  })

  const translate = useAppTranslate()

  return (
    <S.Container $graphics_line={graphics_line}>
      <S.MainChart>
        <S.Header>
          <p>{chart.title}</p>

          <ValidatePermission permission={PermissionsProteu.DashboardWrite}>
            <S.Button id='close' type='button' onClick={() => onDelete()}>
              <BsX color='#201C1C' size={32} />
            </S.Button>
          </ValidatePermission>

          {chart.type === ChartType.ADL && (
            <S.Button id='save-adl' type='button' onClick={() => onSaveADL()}>
              <MdFileDownload color='#201C1C' size={24} />
            </S.Button>
          )}
        </S.Header>
        <S.BodyChart>
          <Chart chart={chart} realtime={true} fftType={fftType} />
        </S.BodyChart>
      </S.MainChart>

      {chart.type === ChartType.TWO && (
        <S.Footer>
          <S.FooterText $isbold={fftType === "Acceleration" ? true : false}>
            {translate.chart.fftAcceleration}
          </S.FooterText>
          <ToggleFFT
            onChange={handleToggleFFT}
            checked={fftType === "Velocity"}
          />
          <S.FooterText $isbold={fftType === "Velocity" ? true : false}>
            {translate.chart.fftVelocity}
          </S.FooterText>
        </S.Footer>
      )}
    </S.Container>
  )
}
