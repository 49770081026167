import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 2px;
  width: 100%;
`

export const WrapperIcon = styled.div<{ $hasOnClickIcon: boolean }>`
  position: relative;
  width: 100%;
`

export const Icon = styled.div<{ $clickable: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: calc(24px - 10px);

  left: ${({ $clickable }) => ($clickable ? "unset" : "1px")};
  right: ${({ $clickable }) => ($clickable ? "1px" : "unset")};

  height: 20px;
  width: 32px;
  background-color: ${({ theme }) => theme.palette.gray[50]};

  cursor: ${({ $clickable }) =>
    $clickable ? "pointer" : "default"}; // Cursor pointer se clicável
`

export const SInput = styled.input<{ $hasIcon: boolean; $iconRight: boolean }>`
  width: 100%;

  height: 48px;

  background-color: ${({ theme }) => theme.palette.gray[50]};

  outline: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  border: 1px solid ${({ theme }) => theme.palette.gray[300]};
  border-radius: 4px;

  text-indent: ${({ $hasIcon, $iconRight }) =>
    $hasIcon
      ? $iconRight
        ? "12px"
        : "calc(12px + 20px + 6px)"
      : "12px"}; // Ajusta o recuo

  &::placeholder {
    color: ${(props) => props.theme.palette.gray[500]};
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: ${(props) => props.theme.typography.text.md.fontSize};
    font-weight: ${(props) => props.theme.typography.fontWeightRegular};
    line-height: ${(props) => props.theme.typography.text.md.lineHeight};
  }

  &:focus {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
  }

  &:disabled {
    &::placeholder {
      color: ${(props) => props.theme.palette.gray[400]};
    }

    p {
      color: ${(props) => props.theme.palette.gray[500]};
    }
  }
`

export const Label = styled.p<{ $disable: boolean | undefined }>`
  color: ${({ $disable, theme }) =>
    $disable ? theme.palette.gray[500] : theme.palette.gray[600]};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.text.xs.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightSemibold};
  line-height: ${(props) => props.theme.typography.text.xs.lineHeight};
`

export const HelperText = styled.p`
  color: ${(props) => props.theme.palette.gray[400]};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.text.xs.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  line-height: ${(props) => props.theme.typography.text.xs.lineHeight};
`

export const ErrorText = styled.p`
  color: ${(props) => props.theme.palette.error.main};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.text.xs.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  line-height: ${(props) => props.theme.typography.text.xs.lineHeight};
`
