import { Container } from "./styles"
import { Text } from "../../../../../components/ui/Text"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"
import { getTypeName } from "../../../../../utils/entities/assets"

interface AssetTypeProps {
  assetType: number
  createdAt: string
}

export const AssetType = ({ assetType, createdAt }: AssetTypeProps) => {
  const { assets } = useAppTranslate()

  return (
    <Container>
      <Text>{`${assets.details.type}: ${getTypeName(assetType)}`}</Text>
      <Text>{`${assets.details.createdAt}: ${createdAt}`}</Text>
    </Container>
  )
}
