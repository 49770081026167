import { useMemo } from "react"
import { toast } from "react-toastify"
import { useForm, useWatch } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { formatDate, SCHEMA_ERRORS } from "../../utils/schema"
import * as z from "zod"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"
import { useLinkSensorMutation } from "../../../../../store/store"

const schema = z.object({
  associatedAt: z.string().trim().min(1, SCHEMA_ERRORS.REQUIRED),
  sensorId: z.string().trim().min(1, SCHEMA_ERRORS.REQUIRED),
})

type ConfigureSensorSchema = z.infer<typeof schema>

interface ConfigureSensorProps {
  assetId: number
  handleCloseModal: () => void
  sensorId?: string
  associatedAt?: number
}

export const useConfigureSensor = ({
  assetId,
  handleCloseModal,
  sensorId,
  associatedAt,
}: ConfigureSensorProps) => {
  const { assets } = useAppTranslate()

  const [updateSensor, mutation] = useLinkSensorMutation()

  const isDisable = useMemo(() => mutation.isLoading, [mutation.isLoading])

  const {
    control,
    setValue,
    register,
    handleSubmit: _handleSubmit,
    formState: { errors },
  } = useForm<ConfigureSensorSchema>({
    resolver: zodResolver(schema),
    defaultValues: {
      associatedAt: associatedAt ? formatDate(associatedAt) : "",
      sensorId: sensorId || "",
    },
  })

  const handleSelectSensor = (id: string | number) => {
    setValue("sensorId", id.toString())
  }

  const date = useWatch({
    control,
    name: "associatedAt",
  })

  const handleConfigureSensor = ({
    sensorId,
    associatedAt,
  }: ConfigureSensorSchema) => {
    updateSensor({
      id: assetId,
      sensorId: Number(sensorId),
      // associatedAt: new Date(associatedAt).getTime(),
    })
      .unwrap()
      .then(handleCloseModal)
      .catch(() => {
        toast.error(assets.form.sensors.error)
      })
  }

  return {
    handleSubmit: _handleSubmit(handleConfigureSensor),
    register,
    handleSelectSensor,
    isDisable,
    isEmpty: !date,
    errors,
  }
}
