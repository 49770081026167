import {
  ChangeEventHandler,
  ForwardedRef,
  HtmlHTMLAttributes,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react"
import {
  Container,
  PlaceholderContainer,
  ImageOptions,
  RemoveImage,
  Wrapper,
  ErrorText,
  ErrorLoadImageText,
  EditImage,
} from "./styles"
import { Image } from "phosphor-react"
import { Button } from "../../../../../components/ui/Button"
import { ImageContainerSkeleton } from "./ImageContainer.skeleton"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"

interface ImageProps extends HtmlHTMLAttributes<HTMLInputElement> {
  src?: string
  editable?: boolean
  error?: string
  onReset?: () => void
}

const useForwardRef = <T,>(ref: ForwardedRef<T>) => {
  const targetRef = useRef<T>(null)

  useEffect(() => {
    if (!ref) return

    if (typeof ref === "function") {
      ref(targetRef.current)
    } else {
      ref.current = targetRef.current
    }
  }, [ref])

  return targetRef
}

const ImageContainer = forwardRef<HTMLInputElement, ImageProps>(
  ({ src, editable = false, onChange, error, onReset, ...props }, ref) => {
    const { assets } = useAppTranslate()

    const inputRef = useForwardRef<HTMLInputElement>(ref)

    const [loadError, setLoadError] = useState(false)

    const [file, setFile] = useState<File>()

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
      const files = event.target.files

      if (!files) return
      if (files.length === 0) return

      setFile(files[0])
      console.log(files[0])
    }

    const handleAddImage = () => {
      setLoadError(false)
      inputRef.current?.click()
    }

    const handleRemoveImage = () => {
      setFile(undefined)
      if (inputRef.current) inputRef.current.value = ""
      setLoadError(false)
      if (onReset) onReset()
    }

    const getImageUrl = (): string | undefined => {
      if (file) return URL.createObjectURL(file)
      if (src) return src

      return undefined
    }

    return (
      <Wrapper>
        <>
          {(file || src) && editable && (
            <ImageOptions>
              <EditImage>
                <Button variant='link' onClick={handleAddImage} type='button'>
                  {assets.form.editImage}
                </Button>
              </EditImage>

              <RemoveImage>
                <Button
                  variant='link'
                  onClick={handleRemoveImage}
                  type='button'
                >
                  {assets.form.removeImage}
                </Button>
              </RemoveImage>
            </ImageOptions>
          )}

          <Container>
            <PlaceholderContainer>
              {((!file && !src) || loadError) && <Image size={96} />}

              {(file || src) && !loadError && (
                <img
                  src={getImageUrl()}
                  onError={(e) => setLoadError(true)}
                  alt='asset'
                />
              )}

              {loadError && (
                <ErrorLoadImageText>
                  {assets.form.imageError}
                </ErrorLoadImageText>
              )}

              {editable && (
                <>
                  <input
                    type='file'
                    onChange={(e) => {
                      handleChange(e)
                      if (onChange) onChange(e)
                    }}
                    ref={inputRef}
                    {...props}
                  />

                  {!file && (
                    <Button
                      variant='link'
                      onClick={handleAddImage}
                      type='button'
                    >
                      {assets.form.addImage}
                    </Button>
                  )}
                </>
              )}
            </PlaceholderContainer>
          </Container>

          {error && <ErrorText>{error}</ErrorText>}
        </>
      </Wrapper>
    )
  }
)

ImageContainer.displayName = "ImageContainer"

export { ImageContainer, ImageContainerSkeleton }
