import { BrowserRouter } from "react-router-dom"
import Router from "./routes"
import { ToastContainer } from "react-toastify"

function App() {
  return (
    <BrowserRouter>
      <Router />
      <ToastContainer
        style={{
          fontSize: "14px",
          zIndex: "99999",
        }}
      />
    </BrowserRouter>
  )
}

export default App
