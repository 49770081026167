import styled from "styled-components"
import { media } from "../../../../utils/media-queries"

export const AssetsContainer = styled.div`
  font-family: ${(props) => props.theme.typography.fontFamily};
  width: 100%;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
  gap: 12px;

  font-size: 16px;

  margin: 0 12px;

  @media ${media.medium} {
    flex-direction: row;
    justify-content: space-between;
    gap: 0px;

    margin: 0 12px 0 0;

    height: 85vh;
  }

  @media ${media.large} {
    margin-right: 0;
  }
`

export const ColumnsWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: end;
  flex-direction: column;
  gap: 12px;

  height: 100%;

  @media ${media.large} {
    flex-direction: row;
    gap: 12px;
    height: 85vh;
    padding: 0;
  }
`

export const Content = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
`

export const MessageContainer = styled.div`
  font-size: medium;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 16px 0px 10px 0px;
  gap: 10px;

  width: 100%;
`

export const HistoryComponent = styled.div`
  box-sizing: border-box;
  background-color: ${(props) => props.theme.palette.gray[50]};
  height: calc(85vh - 28px + 1px);

  border: 1px solid ${(props) => props.theme.palette.gray[400]};
  border-radius: 4px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  margin: 0 12px;

  @media ${media.medium} {
    width: auto;
  }

  @media ${media.large} {
    margin: 0 12px 0 0;
  }
`

export const TreeAssetsHeader = styled.div`
  padding: 16px 0px 0px 16px;
`

export const InputContainer = styled.div`
  padding: 0px 16px;
  display: flex;
  gap: 5px;
`
