import Skeleton from "@mui/material/Skeleton"
import { TreeAssetsSkeletonContainer } from "./styles"
import { InputContainer, TreeAssetsHeader } from "../../../styles"
import { Divider } from "../../../../../../../components/ui/Divider"

export const TreeAssetsSkeleton = () => {
  return (
    <>
      <TreeAssetsHeader>
        <Skeleton variant='rounded' width={120} />
      </TreeAssetsHeader>

      <Divider />
      <InputContainer>
        <Skeleton variant='rectangular' width={273} height={50} />
      </InputContainer>

      <Divider />
      <TreeAssetsSkeletonContainer>
        <Skeleton variant='rounded' width={260} />
        <Skeleton variant='rounded' width={260} />
        <Skeleton variant='rounded' width={260} />
      </TreeAssetsSkeletonContainer>
    </>
  )
}
