import styled from "styled-components"
import { media } from "../../../../../../../utils/media-queries"

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .desktop-button {
    display: none;
  }
  .mobile-icon-button {
    display: block;
  }

  @media ${media.medium} {
    .desktop-button {
      display: block;
    }
    .mobile-icon-button {
      display: none;
    }
  }
`
