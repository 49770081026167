import { memo } from "react"
import { ModalHedro } from "../../../../../components/ModalHedro"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"
import { AssetSensorsTable } from "../AssetSensorsTable"
import { Content } from "./styles"
import { DatetimeInput } from "../../../../../components/ui/DatetimeInput"
import { Text } from "../../../../../components/ui/Text"
import { useConfigureSensor } from "../../List/controllers/useConfigureSensor"

interface ModalConfigureSensorProps {
  handleCloseModal: () => void
  assetId: number
  sensorId?: string
  associatedAt?: number
}

export const ModalConfigureSensor = memo(
  ({
    handleCloseModal,
    assetId,
    sensorId,
    associatedAt,
  }: ModalConfigureSensorProps) => {
    const { assets } = useAppTranslate()

    const {
      handleSubmit,
      register,
      handleSelectSensor,
      isDisable,
      isEmpty,
      errors,
    } = useConfigureSensor({
      assetId,
      handleCloseModal,
      sensorId,
      associatedAt,
    })

    return (
      <ModalHedro
        onClose={handleCloseModal}
        onAction={handleSubmit}
        actionBar={assets.sensors.associateSensor}
        title={assets.sensors.addModalTitle}
        disableAction={isDisable}
      >
        <Content>
          <DatetimeInput
            isEmpty={isEmpty}
            error={errors.associatedAt && assets.form.errors.required}
            type='datetime-local'
            {...register("associatedAt")}
          />
          <AssetSensorsTable
            onCheck={handleSelectSensor}
            defaultValue={sensorId}
          />
          <Text color='error.main' fontSize='xs'>
            {errors.sensorId && assets.form.errors.required}
          </Text>
        </Content>
      </ModalHedro>
    )
  }
)

ModalConfigureSensor.displayName = "ModalConfigureSensor"
