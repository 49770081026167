import { NewHeader } from "../../../../components/NewHeader"
import Column from "../../../../components/ui/Column"
import { TabList } from "../../../../components/ui/TabList"
import { Tabs } from "../../../../components/ui/Tabs"
import { TabPanels } from "../../../../components/ui/TabPanels"
import { TabPanel } from "../../../../components/ui/TabPanel"
import { Tab } from "../../../../components/ui/Tab"
import { TreeContainer } from "./components/TreeContainer"
import { HistoryCard } from "./components/HistoryCard"
import {
  AssetsContainer,
  Content,
  Wrapper,
  MessageContainer,
  HistoryComponent,
  ColumnsWrapper,
} from "./styles"
import { AssetDetails, AssetDetailsSkeleton } from "./components/Details"
import { Button } from "../../../../components/ui/Button"
import { HistoryCardSkeleton } from "./components/Skeleton/HistoryCard"
import { TreeAssetsSkeleton } from "./components/Skeleton/TreeAssets"

import { TreeContent } from "./components/TreeContainer/components/TreeContent"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { useAssets } from "./controllers/useAssets"

export const AssetsWrapper = () => {
  const { assets, lateralMenu } = useAppTranslate()
  const page = {
    title: lateralMenu.routes.assets.title,
  }

  const {
    open,
    assetDetail,
    tree,
    listAssets,
    isError,
    isLoading,
    isFetching,
    canRenderTree,
    dataProvider,
    refetch,
    setClickAsset,
    onClick,
  } = useAssets()

  return (
    <AssetsContainer>
      <NewHeader page={page} />

      <ColumnsWrapper>
        <Column sm='19' md='24' lg='19' xl='19'>
          <Wrapper>
            <TreeContainer open={open} onClick={onClick}>
              {isError && (
                <MessageContainer>
                  {assets.tree.error}
                  <Button variant='link' onClick={refetch}>
                    {assets.reload}
                  </Button>
                </MessageContainer>
              )}

              {(isLoading || isFetching) && <TreeAssetsSkeleton />}

              {canRenderTree && (
                <TreeContent
                  key={JSON.stringify(listAssets)}
                  dataProvider={dataProvider}
                  handleAsset={setClickAsset}
                  tree={tree}
                />
              )}
            </TreeContainer>

            <Content>
              <Tabs>
                <TabList>
                  <Tab>{assets.details.title}</Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    {assetDetail ? (
                      <AssetDetails assetId={assetDetail} />
                    ) : !isError ? (
                      <AssetDetailsSkeleton />
                    ) : (
                      <MessageContainer>
                        {assets.details.error}
                      </MessageContainer>
                    )}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Content>
          </Wrapper>
        </Column>

        <Column sm='5' md='24' lg='5' xl='5'>
          <HistoryComponent>
            {isLoading && <HistoryCardSkeleton />}

            {assetDetail && <HistoryCard assetId={assetDetail} />}

            {isError && (
              <MessageContainer>{assets.history.error}</MessageContainer>
            )}
          </HistoryComponent>
        </Column>
      </ColumnsWrapper>
    </AssetsContainer>
  )
}
