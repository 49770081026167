import styled from "styled-components"

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding-top: 26px;

  margin: 0 0 24px 12px;
`
